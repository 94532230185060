import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import InputAdornment from '@material-ui/core/InputAdornment';
import Snackbar from '@material-ui/core/Snackbar';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import cn from 'classnames';
import { ReturnMessage } from 'components/common/ReturnMessage';
import update from 'immutability-helper';
import get from 'lodash.get';
import { useSession } from 'next-auth/client';
// import ProgressBar from '@badrap/bar-of-progress';
import { SnackbarOrigin, useSnackbar } from 'notistack';
import React, { ChangeEvent, FC, SyntheticEvent, useMemo, useRef, useState } from 'react';
import NumberFormat from 'react-number-format';
import useColorChange from 'use-color-change';

import {
  colorChangeOptions,
  editableCustomType,
  editableOriginalType,
  // defaultProgressBarOptions,
  employeeHistoryRegex,
  Roles,
  SALARY_TYPE_V1,
} from '../../config';
import { getDefaultTeam } from '../../config/targetCaclulatorFornewProjectsDefaultTeam';
import useSocket from '../../hooks/useSocket';
import { useOverheadStore } from '../../store/overhead/action';
import { useTeamStore } from '../../store/team/action';
import { RateType, TargetCalculatorProperties } from '../../types/components/TeamMembers';
import { forcedSignOut, getLastHistoryValue, isNumeric, round } from '../../utils';
import { Editable, ShowNumber, TeamMembers } from '../common';
import { useStyles } from './styles';

const anchorOrigin: SnackbarOrigin = { vertical: 'bottom', horizontal: 'left' };

type RType = keyof typeof RateType;

export const TargetCalculator: FC<TargetCalculatorProperties> = ({
  employees: defaultEmployees,
  overhead: defaultOverhead,
  experiences: defaultExperiences,
  isStaging,
}) => {
  // const progress = new ProgressBar(defaultProgressBarOptions);
  const [session] = useSession();
  const { enqueueSnackbar } = useSnackbar();
  const overheadInputReference = useRef(null);
  const formReference = useRef(null);
  const classes = useStyles();
  const [employees, setEmployees] = useState(defaultEmployees);
  const [experiences, setExperiences] = useState(defaultExperiences);

  const snackbarOptions = {
    anchorOrigin,
    autoHideDuration: 30000,
    preventDuplicate: false,
    className: classes.snackbar,
  };

  const displayOverheadMessage = (value: string): string | number =>
    enqueueSnackbar(`Overhead has been  changed to value: ${value}`, snackbarOptions);

  const [newProjectName, setNewProjectName] = useState('');
  const [reportLink, setReportLink] = useState('');
  const [status, setStatus] = useState(0);
  const [isSnackbaropen, setIsSnackbaropen] = useState(false);
  const [values, setValues] = useState({
    projectRateEU: 15,
    projectRateUS: 20,
    targetProfitability: 14,
    referralFee: 10,
    contingency: 10,
  });

  forcedSignOut(status);
  const calculatorPrecision = isStaging ? 4 : 2;
  const userRole: string = session?.user?.roles ? session.user.roles[0] : '';
  const isDMorAdmin = [Roles.Admin, Roles.DepManager].includes(userRole as Roles);

  const fetchExperienceRates = async (): Promise<void> => {
    const result = await fetch(`/api/experienceRates`);

    forcedSignOut(result.status);
    const data = await result.json();

    setExperiences(data);
  };

  const defaultTeam = getDefaultTeam(isStaging, employees, isDMorAdmin, defaultExperiences);
  const { state: team, actions: teamActions } = useTeamStore(defaultTeam);
  const { state: overhead, actions: overheadActions } = useOverheadStore({
    type: editableOriginalType,
    value: defaultOverhead,
  });

  const fetchOverhead = async (): Promise<void> => {
    const month = new Date().toISOString().slice(0, 7);
    const result = await fetch(`/api/overheads/${month}`);

    forcedSignOut(result.status);
    const data = await result.json();

    return overheadActions.setOverhead({
      overheadType: editableOriginalType,
      value: get(data, 'overhead', 0),
    });
  };

  useSocket('realtime', async (message: Types.Controllers.Watchers.Messages) => {
    const { entity, action } = message;

    if (entity === 'employees') {
      const {
        employee: { id: teamMemberId, name, history: historyObject },
        updateDescription: updatedFields,
        isUSBased,
        isExperienceOrUSBasedChanged,
      } = message as Types.Controllers.Watchers.EmployeeMessage;
      const history = Object.entries(updatedFields)
        .filter(([key]) => key.match(employeeHistoryRegex))
        .map(([key, value]) => {
          const [, entityName, date] = key.match(employeeHistoryRegex) || [];

          return `The "${entityName}" parameter has been set to ${value} since ${date}.`;
        })
        .join(' and ');

      const salaryType = getLastHistoryValue(historyObject.salaryType, false);
      const rate = getLastHistoryValue(historyObject.rate, false);
      const rate2 = getLastHistoryValue(historyObject.rate2, false);
      const stake = getLastHistoryValue(historyObject.stake, false);
      const employmentType = getLastHistoryValue(historyObject.employmentType, false);

      const teamMemberRate =
        salaryType === SALARY_TYPE_V1 ? Number(rate) : Number(rate2) + Number(stake) / (160 * Number(employmentType));

      const employeeIndex = employees.findIndex(({ id }) => id === teamMemberId);

      if (employeeIndex >= 0) {
        setEmployees((_employees) =>
          update(_employees, {
            [employeeIndex]: (member) =>
              update(member, {
                rate: { $set: Number(teamMemberRate) },
              }),
          }),
        );
      }

      teamActions.setMemberRate({ teamMemberId, teamMemberRate });

      const hasExperience =
        isExperienceOrUSBasedChanged ||
        isUSBased ||
        (historyObject.experience && Object.keys(historyObject.experience).length > 0);

      if (hasExperience) {
        fetchExperienceRates();
      }

      enqueueSnackbar(`Employee "${name}" has been updated.\n${history}`, snackbarOptions);
    }

    if (entity === 'overheads' && overhead.type === editableOriginalType) {
      const { month, value } = message as Types.Controllers.Watchers.OverheadMessage;

      if (action === 'delete') {
        enqueueSnackbar(`${month} overhead has been deleted.`, snackbarOptions);

        await fetchOverhead();
      }

      enqueueSnackbar(`Overhead has been  has been set to ${value} since ${month}.`, snackbarOptions);

      overheadActions.setOverhead({
        value: Number(value),
      });
    }
  });

  const changeHandler = async ({ code, value }: { code: string; value: unknown }): Promise<void> => {
    try {
      setValues({ ...values, [code]: value || 0 });
    } catch (error) {
      console.error(error);
    }
  };

  const calc = useMemo(() => {
    values.contingency = values.contingency || 0;

    const teamData = team.map(
      ({
        employee,
        role,
        customRate,
        hours: { billable = 0, absorbed = 0 },
        rateType = RateType.euBillableHours,
        ...rest
      }) => {
        const salaryRate = isNumeric(`${customRate}`) ? customRate : experiences[role];
        const total = billable + absorbed;
        const salary = employee ? Number(employee.rate) * Number(total) : Number(salaryRate || 0) * Number(total);

        return {
          role,
          rate: experiences[role] || 0,
          customRate,
          hours: {
            billable,
            absorbed,
            total,
          },
          employee,
          salary,
          rateType,
          ...rest,
        };
      },
    );

    const { usBillableHours, euBillableHours, absorbedHours, totalHours } = team.reduce(
      (accumulator, { hours: { billable = 0, absorbed = 0 }, rateType = RateType.euBillableHours }) => {
        accumulator[rateType as RType] += billable;
        accumulator.absorbedHours += absorbed;
        accumulator.totalHours += billable + absorbed;

        return accumulator;
      },
      {
        [RateType.usBillableHours]: 0,
        [RateType.euBillableHours]: 0,
        absorbedHours: 0,
        totalHours: 0,
      },
    );
    const totalSalary = teamData.reduce((accumulator, { salary }) => {
      return accumulator + salary;
    }, 0);
    const billableHours = euBillableHours + usBillableHours;
    const euRateRevenue = euBillableHours * values.projectRateEU;
    const usRateRevenue = usBillableHours * values.projectRateUS;
    const revenue = euRateRevenue + usRateRevenue;
    const effectiveRate = revenue / totalHours;
    const targetMargin = (revenue * values.targetProfitability) / 100; // [Target Margin, $] = [S&F Revenue* Target profitability, %]
    const estimateOverhead = billableHours * Number(overhead.value);
    const estimateBudget = totalSalary + estimateOverhead;
    const estimateCostOfHour = estimateBudget / totalHours;
    const budgetWithoutContingency = revenue - targetMargin - (revenue * values.referralFee) / 100;
    const contingency = (budgetWithoutContingency * values.contingency) / 100;
    const budgetWithContingency = budgetWithoutContingency - contingency; // [Budget without contingency - Contingency, $]
    const remainingHoursWeCanGiveAway = budgetWithContingency - estimateBudget; // [Budget with contingency, $-Estimate Budget, $]
    const remainingHoursWeCanGiveAwayHours = remainingHoursWeCanGiveAway / estimateCostOfHour; // [Remaining hours we can give away and still hit the target, $/Estimate Cost of hour, $]
    const hoursWeCanGiveAway =
      (revenue - (revenue * values.referralFee) / 100 - contingency - estimateBudget) / estimateCostOfHour;
    // [S&F Revenue-S&F Revenue*Referral fee-Contingency, $-Calculate the spent budget, $-Estimate Budget, $]
    const projectMarginWithoutContingency = revenue - (revenue * values.referralFee) / 100 - totalSalary;
    const projectMarginPercentWithoutContingency = revenue ? (projectMarginWithoutContingency / revenue) * 100 : 0;
    const finalAGMSumWithContingency = revenue - (revenue * values.referralFee) / 100 - contingency - estimateBudget;
    const finalAGMSumWithoutContingency = revenue - (revenue * values.referralFee) / 100 - estimateBudget; // [S&F Revenue-S&F Revenue*Referral fee-Calculate the spent budget, $-Estimate Budget, $]
    const finalAGMWithContingency = revenue ? (finalAGMSumWithContingency / revenue) * 100 : 0; //  [Final AGM, $/S&F Revenue]
    const finalAGMWithoutContingency = revenue ? (finalAGMSumWithoutContingency / revenue) * 100 : 0; // [Final AGM, $/S&F Revenue]

    return {
      targetMargin: round(targetMargin),
      budgetWithoutContingency: round(budgetWithoutContingency),
      contingency: round(contingency),
      budgetWithContingency: round(budgetWithContingency),
      team: teamData,
      billableHours: round(billableHours),
      absorbedHours: round(absorbedHours),
      totalHours: round(totalHours),
      revenue: round(revenue),
      effectiveRate: round(effectiveRate),
      totalSalary: round(totalSalary),
      estimateOverhead: round(estimateOverhead),
      estimateBudget: round(estimateBudget),
      estimateCostOfBillableHour: round(estimateCostOfHour),
      remainingHoursWeCanGiveAway: round(remainingHoursWeCanGiveAway),
      remainingHoursWeCanGiveAwayHours: round(remainingHoursWeCanGiveAwayHours),
      hoursWeCanGiveAway: round(hoursWeCanGiveAway),
      projectMarginWithoutContingency: round(projectMarginWithoutContingency),
      projectMarginPercentWithoutContingency: round(projectMarginPercentWithoutContingency),
      finalAGMSumWithContingency: round(finalAGMSumWithContingency),
      finalAGMSumWithoutContingency: round(finalAGMSumWithoutContingency),
      finalAGMWithContingency: round(finalAGMWithContingency),
      finalAGMWithoutContingency: round(finalAGMWithoutContingency),
    };
  }, [values, team, overhead.value, experiences]);

  const changeNewProjectName = (event: SyntheticEvent): void => {
    const target = event.target as HTMLTextAreaElement;

    setNewProjectName(target.value);
  };

  const generateReport = async (): Promise<void> => {
    // @ts-ignore
    const validated = formReference.current.reportValidity();

    if (validated) {
      const result = await fetch('/api/calculator/generateTargetReport', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          newProjectName,
          values,
          calc,
          overhead,
        }),
      });

      const report = await result.json();

      setReportLink(`https://docs.google.com/spreadsheets/d/${report.spreadsheetId}/edit#gid=${report.sheetId}`);
      setStatus(result.status);
      setIsSnackbaropen(true);
    }
  };

  const handleCloseSnackbar = (): void => setIsSnackbaropen(false);

  const overheadChangeHandler = (event: ChangeEvent<HTMLInputElement>): void =>
    overheadActions.setOverhead({
      overheadType: editableCustomType,
      value: Number(event.target.value),
    });

  const revenueColorStyle = useColorChange(Number(calc.revenue), colorChangeOptions);
  const effectiveRateColorStyle = useColorChange(Number(calc.effectiveRate), colorChangeOptions);
  const targetMarginColorStyle = useColorChange(Number(calc.targetMargin), colorChangeOptions);
  const budgetWithoutContingencyColorStyle = useColorChange(Number(calc.budgetWithoutContingency), colorChangeOptions);
  const contingencyColorStyle = useColorChange(Number(calc.contingency), colorChangeOptions);
  const budgetWithContingencyColorStyle = useColorChange(Number(calc.budgetWithContingency), colorChangeOptions);

  const overheadColorStyle = useColorChange(Number(overhead.value), colorChangeOptions);
  const totalHoursColorStyle = useColorChange(Number(calc.totalHours), colorChangeOptions);
  const totalSalaryColorStyle = useColorChange(Number(calc.totalSalary), colorChangeOptions);
  const estimateOverheadColorStyle = useColorChange(Number(calc.estimateOverhead), colorChangeOptions);
  const estimateBudgetColorStyle = useColorChange(Number(calc.estimateBudget), colorChangeOptions);
  const estimateCostOfHourColorStyle = useColorChange(Number(calc.estimateCostOfBillableHour), colorChangeOptions);

  const remainingHoursWeCanGiveAwayHoursColorStyle = useColorChange(
    Number(calc.remainingHoursWeCanGiveAwayHours),
    colorChangeOptions,
  );
  const remainingHoursWeCanGiveAwayColorStyle = useColorChange(
    Number(calc.remainingHoursWeCanGiveAway),
    colorChangeOptions,
  );
  const hoursWeCanGiveAwayColorStyle = useColorChange(Number(calc.hoursWeCanGiveAway), colorChangeOptions);
  const finalAGMSumWithContingencyColorStyle = useColorChange(
    Number(calc.finalAGMSumWithContingency),
    colorChangeOptions,
  );
  const finalAGMSumWithoutContingencyColorStyle = useColorChange(
    Number(calc.finalAGMSumWithoutContingency),
    colorChangeOptions,
  );
  const finalAGMWithContingencyColorStyle = useColorChange(Number(calc.finalAGMWithContingency), colorChangeOptions);
  const finalAGMWithoutContingencyColorStyle = useColorChange(
    Number(calc.finalAGMWithoutContingency),
    colorChangeOptions,
  );

  return (
    <>
      <form className={classes.root} noValidate autoComplete="off" ref={formReference}>
        <Typography variant="h6" className={classes.header}>
          Settings
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} md={8}>
            <FormControl className={classes.formControl}>
              <TextField
                required
                value={newProjectName}
                autoFocus
                fullWidth
                size="small"
                variant="outlined"
                label="New Project Name"
                onChange={changeNewProjectName}
              />
            </FormControl>

            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <FormControl className={classes.formControl}>
                  <NumberFormat
                    className={cn(classes.condensed, classes.small)}
                    customInput={TextField}
                    thousandSeparator
                    variant="outlined"
                    id="projectRateEU"
                    label="Project Rate EU"
                    defaultValue={values.projectRateEU}
                    InputProps={{
                      startAdornment: <InputAdornment position="start">$</InputAdornment>,
                    }}
                    onValueChange={({ floatValue }) => changeHandler({ code: 'projectRateEU', value: floatValue })}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl className={classes.formControl}>
                  <NumberFormat
                    className={cn(classes.condensed, classes.small)}
                    customInput={TextField}
                    thousandSeparator
                    variant="outlined"
                    id="projectRateUS"
                    label="Project Rate US"
                    defaultValue={values.projectRateUS}
                    InputProps={{
                      startAdornment: <InputAdornment position="start">$</InputAdornment>,
                    }}
                    onValueChange={({ floatValue }) => changeHandler({ code: 'projectRateUS', value: floatValue })}
                  />
                </FormControl>
              </Grid>
            </Grid>

            <FormControl className={classes.formControl}>
              <NumberFormat
                className={cn(classes.condensed, classes.small)}
                customInput={TextField}
                variant="outlined"
                id="targetProfitability"
                label="Target profitability"
                defaultValue={values.targetProfitability}
                InputProps={{
                  endAdornment: <InputAdornment position="end">%</InputAdornment>,
                }}
                onChange={(event: ChangeEvent<HTMLInputElement>) =>
                  changeHandler({ code: 'targetProfitability', value: Number(event.target.value) })
                }
              />
            </FormControl>
            <FormControl className={classes.formControl}>
              <NumberFormat
                className={cn(classes.condensed, classes.small)}
                customInput={TextField}
                variant="outlined"
                id="referralFee"
                label="Referral fee"
                defaultValue={values.referralFee}
                InputProps={{
                  endAdornment: <InputAdornment position="end">%</InputAdornment>,
                }}
                onChange={(event: ChangeEvent<HTMLInputElement>) =>
                  changeHandler({ code: 'referralFee', value: Number(event.target.value) })
                }
              />
            </FormControl>
            <FormControl className={classes.formControl}>
              <NumberFormat
                className={cn(classes.condensed, classes.small)}
                customInput={TextField}
                variant="outlined"
                id="contingency"
                label="Contingency"
                defaultValue={values.contingency}
                InputProps={{
                  endAdornment: <InputAdornment position="end">%</InputAdornment>,
                }}
                onValueChange={({ floatValue }) => changeHandler({ code: 'contingency', value: floatValue })}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} md={4}>
            <ul className={classes.calcInfo}>
              <li>
                <strong>S&F Revenue: </strong>
                <ShowNumber number={calc.revenue} prefix="$" style={revenueColorStyle} />
              </li>
              <li>
                <strong>Effective rate: </strong>
                <ShowNumber number={calc.effectiveRate} prefix="$" style={effectiveRateColorStyle} />
              </li>
              <li>
                <strong>Target Margin: </strong>
                <ShowNumber number={calc.targetMargin} prefix="$" style={targetMarginColorStyle} />
              </li>
              <li>
                <strong>Budget without contingency: </strong>
                <ShowNumber
                  number={calc.budgetWithoutContingency}
                  prefix="$"
                  style={budgetWithoutContingencyColorStyle}
                />
              </li>
              <li>
                <strong>Contingency: </strong>
                <ShowNumber number={calc.contingency} prefix="$" style={contingencyColorStyle} />
              </li>
              <li>
                <strong>Budget with contingency: </strong>
                <ShowNumber number={calc.budgetWithContingency} prefix="$" style={budgetWithContingencyColorStyle} />
              </li>
            </ul>
          </Grid>
        </Grid>

        <Typography variant="h6">Team</Typography>

        <Grid container spacing={2}>
          <Grid item xs={12} md={8}>
            <TeamMembers
              team={calc.team}
              employees={employees}
              onDeleteTeamMember={teamActions.deleteTeamMember}
              onChangeRole={teamActions.changeRole}
              onChangeEmployee={teamActions.changeEmployee}
              onChangeHours={teamActions.setHours}
              onChangeCustomRate={teamActions.setRoleCustomRate}
              onRemoveCustomRate={teamActions.removeRoleCustomRate}
              onSetRateType={teamActions.setRateType}
              billableHours={calc.billableHours || 0}
              absorbedHours={calc.absorbedHours || 0}
              totalHours={calc.totalHours}
              totalSalary={calc.totalSalary}
              experiences={experiences}
              precision={calculatorPrecision}
              splitHours
              needsRateType
            />
            <div className={cn(classes.formControl, classes.addTeamMemberButton)}>
              <Button onClick={teamActions.addTeamMember}>Add team member</Button>
            </div>
          </Grid>
          <Grid item xs={12} md={4}>
            <ul className={classes.calcInfo}>
              <li>
                <strong>Overhead/Hour: </strong>
                <Editable
                  style={overheadColorStyle}
                  text={`$${Number(overhead.value)
                    .toFixed(2)
                    .replace(/\\d(?=(\\d{3})+\\.)/g, '$&,')}`}
                  type="input"
                  childRef={overheadInputReference}
                  hint={
                    overhead.type === editableCustomType && (
                      <Button onClick={fetchOverhead} color="primary">
                        Get Overhead from the Config
                      </Button>
                    )
                  }
                  onClose={displayOverheadMessage}
                >
                  <input
                    className={classes.overheadInput}
                    ref={overheadInputReference}
                    type="text"
                    placeholder="Overhead"
                    value={overhead.value}
                    onChange={overheadChangeHandler}
                  />
                </Editable>
              </li>
              <li>
                <strong>Estimate Hours: </strong>
                <ShowNumber number={calc.totalHours} style={totalHoursColorStyle} />
              </li>
              <li>
                <strong>Estimate Delivery Salary: </strong>
                <ShowNumber number={calc.totalSalary} prefix="$" style={totalSalaryColorStyle} />
              </li>
              <li>
                <strong>Estimate Overhead: </strong>
                <ShowNumber number={calc.estimateOverhead} prefix="$" style={estimateOverheadColorStyle} />
              </li>
              <li>
                <strong>Estimate Budget: </strong>
                <ShowNumber number={calc.estimateBudget} prefix="$" style={estimateBudgetColorStyle} />
              </li>
              <li>
                <strong>Estimate Cost of hour: </strong>
                <ShowNumber number={calc.estimateCostOfBillableHour} prefix="$" style={estimateCostOfHourColorStyle} />
              </li>
            </ul>

            <Table size="small" aria-label="a dense table">
              <TableHead>
                <TableRow className={classes.tableHeader}>
                  <TableCell />
                  <TableCell align="right">Hours</TableCell>
                  <TableCell align="right">Salary</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Remaining hours we can give away and still hit the target
                  </TableCell>
                  <TableCell align="right">
                    <ShowNumber
                      number={calc.remainingHoursWeCanGiveAwayHours}
                      style={remainingHoursWeCanGiveAwayHoursColorStyle}
                    />
                  </TableCell>
                  <TableCell align="right">
                    <ShowNumber
                      number={calc.remainingHoursWeCanGiveAway}
                      prefix="$"
                      style={remainingHoursWeCanGiveAwayColorStyle}
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Hours we can give away and still hit $0
                  </TableCell>
                  <TableCell align="right">
                    <ShowNumber number={calc.hoursWeCanGiveAway} style={hoursWeCanGiveAwayColorStyle} />
                  </TableCell>
                  <TableCell />
                </TableRow>

                <TableRow className={classes.tableHeader}>
                  <TableCell>Final data</TableCell>
                  <TableCell>With Contingency </TableCell>
                  <TableCell>Without Contingency</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Project Margin, $
                  </TableCell>
                  <TableCell align="center">-</TableCell>
                  <TableCell align="right">
                    <ShowNumber
                      number={calc.projectMarginWithoutContingency}
                      prefix="$"
                      style={finalAGMSumWithContingencyColorStyle}
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Project margin, %
                  </TableCell>
                  <TableCell align="center">-</TableCell>
                  <TableCell align="right">
                    <ShowNumber
                      number={calc.projectMarginPercentWithoutContingency}
                      suffix="%"
                      style={finalAGMSumWithContingencyColorStyle}
                    />
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell component="th" scope="row">
                    Final Company AGM, $
                  </TableCell>
                  <TableCell align="right">
                    <ShowNumber
                      number={calc.finalAGMSumWithContingency}
                      prefix="$"
                      style={finalAGMSumWithContingencyColorStyle}
                    />
                  </TableCell>
                  <TableCell align="right">
                    <ShowNumber
                      number={calc.finalAGMSumWithoutContingency}
                      prefix="$"
                      style={finalAGMSumWithoutContingencyColorStyle}
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Final Company AGM, %
                  </TableCell>
                  <TableCell align="right">
                    <ShowNumber
                      number={calc.finalAGMWithContingency}
                      suffix="%"
                      style={finalAGMWithContingencyColorStyle}
                    />
                  </TableCell>
                  <TableCell align="right">
                    <ShowNumber
                      number={calc.finalAGMWithoutContingency}
                      suffix="%"
                      style={finalAGMWithoutContingencyColorStyle}
                    />
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Grid>
        </Grid>

        <div className={cn(classes.formControl, classes.reportButton)}>
          <Button color="primary" variant="contained" fullWidth onClick={generateReport}>
            Export to Google sheet
          </Button>
        </div>
      </form>

      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={isSnackbaropen}
        onClose={handleCloseSnackbar}
      >
        <ReturnMessage handleClose={handleCloseSnackbar} reportLink={reportLink} status={status} />
      </Snackbar>
    </>
  );
};
// @ts-ignore
TargetCalculator.whyDidYouRender = true;
