import update from 'immutability-helper';
import { RateType } from 'types/components/TeamMembers';

import { FixBidCalculatorConfig } from '../../config';
import {
  ADD_MEMBER,
  CHANGE_EMPLOYEE,
  CHANGE_ROLE,
  DELETE_MEMBER,
  REMOVE_ROLE_CUSTOM_RATE,
  SET_HOURS,
  SET_MEMBER_RATE,
  SET_RATE_TYPE,
  SET_ROLE_CUSTOM_RATE,
  UPDATE_MEMBER,
} from './consts';

export const Reducer = (
  state: Types.Store.Team.TeamState,
  action: Types.Store.Team.Actions,
): Types.Store.Team.TeamState => {
  switch (action.type) {
    case ADD_MEMBER:
      return [...state, action.teamMember];

    case UPDATE_MEMBER: {
      const index = state.findIndex(({ id }) => id === action.teamMember.id);

      if (index < 0) {
        return state;
      }

      return update(state, {
        [index]: (member) => update(member, { $set: action.teamMember }),
      });
    }
    case SET_ROLE_CUSTOM_RATE: {
      const index = state.findIndex(({ id }) => id === action.teamRoleId);

      if (index < 0) {
        return state;
      }

      return update(state, {
        [index]: {
          customRate: {
            $set: Number(action.teamRoleRate),
          },
        },
      });
    }
    case REMOVE_ROLE_CUSTOM_RATE: {
      const index = state.findIndex(({ id }) => id === action.teamRoleId);

      if (index < 0) {
        return state;
      }

      return update(state, {
        [index]: {
          $unset: ['customRate'],
        },
      });
    }

    case SET_MEMBER_RATE: {
      const index = state.findIndex(({ employee }) => employee && employee.id === action.teamMemberId);

      if (index < 0) {
        return state;
      }

      return update(state, { [index]: { employee: { rate: { $set: Number(action.teamMemberRate) } } } });
    }

    case CHANGE_EMPLOYEE: {
      const index = state.findIndex(({ id }) => id === action.teamRoleId);

      if (index < 0) {
        return state;
      }

      return update(state, { [index]: { employee: { $set: action.employee || undefined } } });
    }

    case SET_HOURS: {
      const index = state.findIndex(({ id }) => id === action.teamRoleId);

      if (index < 0) {
        return state;
      }

      return update(state, { [index]: { hours: { [action.part]: { $set: Number(action.hours) } } } });
    }
    case CHANGE_ROLE: {
      const index = state.findIndex(({ id }) => id === action.teamRoleId);
      const { defaultEmployee, role } = action;

      if (index < 0) {
        return state;
      }

      // @ts-ignore
      const { control, role: roleName } = FixBidCalculatorConfig.teamRoles.find((teamRole) => teamRole.role === role);

      if (control === 'rate') {
        return update(state, {
          [index]: {
            control: { $set: 'rate' },
            role: { $set: roleName },
            rateType: { $set: roleName === 'US based employee' ? RateType.usBillableHours : RateType.euBillableHours },
            $unset: ['customRate', 'employee'],
          },
        });
      }

      if (control === 'employeeSelect') {
        return update(state, {
          [index]: {
            control: { $set: 'employeeSelect' },
            role: { $set: roleName },
            employee: { $set: defaultEmployee },
            rateType: { $set: roleName === 'US based employee' ? RateType.usBillableHours : RateType.euBillableHours },
            $unset: ['customRate'],
          },
        });
      }

      return state;
    }
    case DELETE_MEMBER: {
      const index = state.findIndex(({ id }) => id === action.teamMemberId);

      if (index < 0) {
        return state;
      }

      return update(state, { $splice: [[index, 1]] });
    }
    case SET_RATE_TYPE: {
      const index = state.findIndex(({ id }) => id === action.teamMemberId);

      if (index < 0) {
        return state;
      }

      return update(state, {
        [index]: {
          rateType: {
            $set: action.rateType,
          },
        },
      });
    }
    default:
      return state;
  }
};
