import ProgressBar from '@badrap/bar-of-progress';
import DateFnsUtils from '@date-io/date-fns';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import InputAdornment from '@material-ui/core/InputAdornment';
import Snackbar from '@material-ui/core/Snackbar';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { convertDate } from '@server/utils';
import cn from 'classnames';
import { ReturnMessage } from 'components/common/ReturnMessage';
import update from 'immutability-helper';
import get from 'lodash.get';
import { useSession } from 'next-auth/client';
import { SnackbarOrigin, useSnackbar } from 'notistack';
import React, { ChangeEvent, FC, SyntheticEvent, useEffect, useMemo, useReducer, useRef, useState } from 'react';
import NumberFormat from 'react-number-format';
import useColorChange from 'use-color-change';

import {
  colorChangeOptions,
  defaultProgressBarOptions,
  editableCustomType,
  editableOriginalType,
  employeeHistoryRegex,
  Roles,
  SALARY_TYPE_V1,
} from '../../config';
import { getDefaultTeam } from '../../config/fixBidCaclulatorDefaultTeam';
import useSocket from '../../hooks/useSocket';
import { projectReducer } from '../../reducers/projectReducer';
import { useOverheadStore } from '../../store/overhead/action';
import { useTeamStore } from '../../store/team/action';
import theme from '../../theme';
import { FixBidCalculatorProperties, Project } from '../../types/components/TeamMembers';
import { forcedSignOut, getLastHistoryValue, isNumeric, projectBillableTypes, round } from '../../utils';
import { Editable, ShowNumber, TeamMembers } from '../common';
import CalendarToolbar from '../common/CalendarToolbar/CalendarToolbar';
import { useStyles } from './styles';

const anchorOrigin: SnackbarOrigin = { vertical: 'bottom', horizontal: 'left' };

export const FixBidCalculator: FC<FixBidCalculatorProperties> = ({
  projects,
  employees: defaultEmployees,
  overhead: defaultOverhead,
  experiences: defaultExperiences,
  isStaging,
  syncStartDate,
}) => {
  const [session] = useSession();
  let fetchProjectTimer: number;
  const { enqueueSnackbar } = useSnackbar();
  const overheadInputReference = useRef(null);
  const formReference = useRef(null);

  const classes = useStyles();
  const [employees, setEmployees] = useState(defaultEmployees);
  const [experiences, setExperiences] = useState(defaultExperiences);

  const snackbarOptions = {
    anchorOrigin,
    autoHideDuration: 30000,
    preventDuplicate: false,
    className: classes.snackbar,
  };

  const [newProjectName, setNewProjectName] = useState('');
  const [isOldProject, setIsOldProject] = useState(false);
  const [reportLink, setReportLink] = useState('');
  const [status, setStatus] = useState(0);
  const [isSnackbaropen, setIsSnackbarOpen] = useState(false);
  const [values, setValues] = useState({
    revenue: 20000,
    targetProfitability: 20,
    referralFee: 10,
    contingency: 15,
    // project: projects[0],
    calculateTheSpentBudget: 0,
  });

  forcedSignOut(status);

  const defaultFrom = syncStartDate ? new Date(syncStartDate) : null;
  const minimumDate = defaultFrom && `${defaultFrom.toLocaleString('en-US').split(',')[0]}`;
  const defaultTo = new Date();
  const [range, setRange] = useState<{
    from: Date | null;
    to: Date | null;
  }>({
    from: defaultFrom,
    to: defaultTo,
  });

  const calculatorPrecision = isStaging ? 4 : 2;
  const userRole: string = session?.user?.roles ? session.user.roles[0] : '';
  const isDMorAdmin = [Roles.Admin, Roles.DepManager].includes(userRole as Roles);

  const fetchExperienceRates = async (): Promise<void> => {
    const result = await fetch(`/api/experienceRates`);

    forcedSignOut(result.status);
    const data = await result.json();

    setExperiences(data);
  };

  const defaultTeam = getDefaultTeam(isStaging, employees, isDMorAdmin, defaultExperiences);
  const { state: team, actions: teamActions } = useTeamStore(defaultTeam);
  const { state: overhead, actions: overheadActions } = useOverheadStore({
    type: editableOriginalType,
    value: defaultOverhead,
  });
  const fetchOverhead = async (): Promise<void> => {
    const month = new Date().toISOString().slice(0, 7);
    const result = await fetch(`/api/overheads/${month}`);

    forcedSignOut(result.status);
    const data = await result.json();

    return overheadActions.setOverhead({
      overheadType: editableOriginalType,
      value: get(data, 'overhead', 0),
    });
  };

  const [projectStore, dispatchProject] = useReducer(projectReducer, {
    project: projects[0],
    spentBudgetTimestamp: new Date(),
  });

  const { project, spentBudgetTimestamp } = projectStore;

  // @ts-ignore
  useEffect(() => {
    const progress = new ProgressBar(defaultProgressBarOptions);
    const abortController = new AbortController();
    const { signal } = abortController;

    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    const fetchSpentBudget = async () => {
      try {
        if (!project.id) {
          setValues((currentValues) => ({ ...currentValues, calculateTheSpentBudget: 0 }));

          return null;
        }
        progress.start();
        const from = range.from && !isNaN(range.from.valueOf()) && `from=${convertDate(range.from)}`;
        const to = range.to && !isNaN(range.to.valueOf()) && `to=${convertDate(range.to)}`;
        const query = [from, to].filter(Boolean).join('&');
        const result = await fetch(`/api/projects/${project.id}?${query}`, {
          signal,
        });

        forcedSignOut(result.status);
        const data = await result.json();

        progress.finish();

        setValues((currentValues) => ({ ...currentValues, calculateTheSpentBudget: round(Number(data.spentBudget)) }));
      } catch (error) {
        if (!signal.aborted) {
          console.error(error);
        }
      }
    };

    fetchSpentBudget();

    return () => {
      abortController.abort();
      progress.finish();
    };
  }, [project, range, spentBudgetTimestamp]);

  useSocket('realtime', async (message: Types.Controllers.Watchers.Messages) => {
    const { entity, action } = message;

    if (entity === 'projects') {
      const {
        project: { name, id: redmineId },
      } = message as Types.Controllers.Watchers.ProjectMessage;

      if (project.id === redmineId) {
        dispatchProject({ type: 'FETCH_SPENT_BUDGET' });
        enqueueSnackbar(`Project "${name}" has been updated`, snackbarOptions);
      }
    }

    if (entity === 'employees') {
      const {
        employee: { id: teamMemberId, name, history: historyObject },
        updateDescription: updatedFields,
        affectedProjects,
        isUSBased,
        isExperienceOrUSBasedChanged,
      } = message as Types.Controllers.Watchers.EmployeeMessage;
      const history = Object.entries(updatedFields)
        .filter(([key]) => key.match(employeeHistoryRegex))
        .map(([key, value]) => {
          const [, entityName, date] = key.match(employeeHistoryRegex) || [];

          return `The "${entityName}" parameter has been set to ${value} since ${date}.`;
        })
        .join(' and ');

      const salaryType = getLastHistoryValue(historyObject.salaryType, false);
      const rate = getLastHistoryValue(historyObject.rate, false);
      const rate2 = getLastHistoryValue(historyObject.rate2, false);
      const stake = getLastHistoryValue(historyObject.stake, false);
      const employmentType = getLastHistoryValue(historyObject.employmentType, false);

      const teamMemberRate =
        salaryType === SALARY_TYPE_V1 ? Number(rate) : Number(rate2) + Number(stake) / (160 * Number(employmentType));

      const employeeIndex = employees.findIndex(({ id }) => id === teamMemberId);

      if (employeeIndex >= 0) {
        setEmployees((_employees) =>
          update(_employees, {
            [employeeIndex]: (member) =>
              update(member, {
                rate: { $set: Number(teamMemberRate) },
              }),
          }),
        );
      }

      teamActions.setMemberRate({ teamMemberId, teamMemberRate });

      const hasExperience =
        isExperienceOrUSBasedChanged ||
        isUSBased ||
        (historyObject.experience && Object.keys(historyObject.experience).length > 0);

      if (hasExperience) {
        fetchExperienceRates();
      }

      dispatchProject({ type: 'FETCH_SPENT_BUDGET', affectedProjects });

      enqueueSnackbar(`Employee "${name}" has been updated.\n${history}`, snackbarOptions);
    }

    if (entity === 'overheads' && overhead.type === editableOriginalType) {
      const { month, value } = message as Types.Controllers.Watchers.OverheadMessage;
      const isSpentBudgetAffected = !range.to || new Date(`${month}-01`).getTime() < new Date(range.to).getTime();

      if (isSpentBudgetAffected) {
        if (action === 'delete') {
          enqueueSnackbar(`${month} overhead has been deleted.`, snackbarOptions);
          await fetchOverhead();
          dispatchProject({ type: 'FETCH_SPENT_BUDGET' });
        }

        enqueueSnackbar(`Overhead has been  has been set to ${value} since ${month}.`, snackbarOptions);

        overheadActions.setOverhead({
          value: Number(value),
        });

        dispatchProject({ type: 'FETCH_SPENT_BUDGET' });
      }
    }

    if (entity === 'timeEntries') {
      const projectId = get(message, 'projectId');

      if (project.id === projectId) {
        if (fetchProjectTimer) {
          window.clearTimeout(fetchProjectTimer);
        }

        fetchProjectTimer = window.setTimeout(async () => {
          dispatchProject({ type: 'FETCH_SPENT_BUDGET' });
          enqueueSnackbar(`The spent budget has been updated`, snackbarOptions);
        }, 2000);
      }
    }
  });

  const changeHandler = async ({ code, value }: { code: string; value: unknown }): Promise<void> => {
    try {
      setValues({ ...values, [code]: value || 0 });
    } catch (error) {
      console.error(error);
    }
  };

  const changeProjectHandler = async (projectData: Project): Promise<void> => {
    setNewProjectName(projectData.name);
    setIsOldProject(true);
    dispatchProject({
      type: 'CHANGE_PROJECT',
      project: projectData,
    });
  };

  const calc = useMemo(() => {
    values.contingency = values.contingency || 0;

    const targetMargin = (values.revenue * values.targetProfitability) / 100;
    const budgetWithoutContingency = values.revenue - targetMargin - (values.revenue * values.referralFee) / 100;
    const contingency = (budgetWithoutContingency * values.contingency) / 100;
    const budgetPriorToStartingTheWork = budgetWithoutContingency - contingency; // [Budget without contingency - Contingency, $]
    // Todo: fetch Data
    const availableBudget = budgetPriorToStartingTheWork - values.calculateTheSpentBudget; // [Budget prior to starting the work-Calculate the spent budget, $]
    const teamData = team.map(({ employee, role, customRate, hours, ...rest }) => {
      const { billable = 0 } = hours || {};
      const salaryRate = isNumeric(`${customRate}`) ? customRate : experiences[role];
      const salary = employee
        ? Number(employee.rate || 0) * Number(billable)
        : Number(salaryRate || 0) * Number(billable);

      return {
        role,
        rate: experiences[role] || 0,
        customRate,
        hours: { billable },
        employee,
        salary,
        ...rest,
      };
    });

    const totalHours = team.reduce((accumulator, { hours }) => {
      const { billable = 0 } = hours || {};

      return accumulator + billable;
    }, 0);
    const totalSalary = teamData.reduce((accumulator, { salary }) => {
      return accumulator + salary;
    }, 0);
    const estimateOverhead = totalHours * Number(overhead.value);
    const estimateBudget = totalSalary + estimateOverhead;
    const estimateCostOfHour = estimateBudget / totalHours;
    const availableBudgetHours = availableBudget / estimateCostOfHour; // [Available budget, $/Estimate Cost of hour]
    const remainingHoursWeCanGiveAwayHours = availableBudgetHours - totalHours; // [Available budget, hours - Estimate Hours]
    const remainingHoursWeCanGiveAway = remainingHoursWeCanGiveAwayHours * estimateCostOfHour; // [Remaining hours we can give away*Estimate Cost of hour]
    const projectMarginWithoutContingency = values.revenue - (values.revenue * values.referralFee) / 100 - totalSalary;
    const finalAGMSumWithContingency =
      values.revenue -
      (values.revenue * values.referralFee) / 100 -
      contingency -
      values.calculateTheSpentBudget -
      estimateBudget; // [S&F Revenue-S&F Revenue*Referral fee-Contingency, $-Calculate the spent budget, $-Estimate Budget, $]
    const finalAGMSumWithoutContingency =
      values.revenue - (values.revenue * values.referralFee) / 100 - values.calculateTheSpentBudget - estimateBudget; // [S&F Revenue-S&F Revenue*Referral fee-Calculate the spent budget, $-Estimate Budget, $]

    let projectMarginPercentWithoutContingency = 0;
    let finalAGMWithContingency = 0;
    let finalAGMWithoutContingency = 0;

    if (values.revenue !== 0) {
      projectMarginPercentWithoutContingency = (projectMarginWithoutContingency / values.revenue) * 100;
      finalAGMWithContingency = (finalAGMSumWithContingency / values.revenue) * 100; //  [Final AGM, $/S&F Revenue]
      finalAGMWithoutContingency = (finalAGMSumWithoutContingency / values.revenue) * 100; // [Final AGM, $/S&F Revenue]
    }
    const hoursWeCanGiveAway = finalAGMSumWithContingency / estimateCostOfHour; // [(S&F Revenue-S&F Revenue*Referral fee-Contingency, $-Calculate the spent budget, $-Estimate Budget, $)/Estimate Cost of hour]

    return {
      targetMargin: round(targetMargin),
      budgetWithoutContingency: round(budgetWithoutContingency),
      contingency: round(contingency),
      budgetWithContingency: round(budgetPriorToStartingTheWork),
      availableBudget: round(availableBudget),
      team: teamData,
      totalHours: round(totalHours),
      totalSalary: round(totalSalary),
      estimateOverhead: round(estimateOverhead),
      estimateBudget: round(estimateBudget),
      estimateCostOfBillableHour: round(estimateCostOfHour),
      availableBudgetHours: round(availableBudgetHours),
      remainingHoursWeCanGiveAwayHours: round(remainingHoursWeCanGiveAwayHours),
      remainingHoursWeCanGiveAway: round(remainingHoursWeCanGiveAway),
      hoursWeCanGiveAway: round(hoursWeCanGiveAway),
      projectMarginWithoutContingency: round(projectMarginWithoutContingency),
      projectMarginPercentWithoutContingency: round(projectMarginPercentWithoutContingency),
      finalAGMSumWithContingency: round(finalAGMSumWithContingency),
      finalAGMSumWithoutContingency: round(finalAGMSumWithoutContingency),
      finalAGMWithContingency: round(finalAGMWithContingency),
      finalAGMWithoutContingency: round(finalAGMWithoutContingency),
    };
  }, [values, team, overhead.value, experiences]);

  const changeNewProjectName = (event: SyntheticEvent): void => {
    const target = event.target as HTMLTextAreaElement;

    setNewProjectName(target.value);
  };

  const generateReport = async (): Promise<number | undefined> => {
    // @ts-ignore
    const validated = formReference.current.reportValidity();

    if (validated) {
      const result = await fetch('/api/calculator/generateFixBidReport', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          newProjectName,
          isOldProject,
          values,
          calc,
          range,
          overhead,
        }),
      });

      const report = await result.json();

      setReportLink(`https://docs.google.com/spreadsheets/d/${report.spreadsheetId}/edit#gid=${report.sheetId}`);
      setStatus(result.status);
      setIsSnackbarOpen(true);

      return result.status;
    }
  };

  const handleCloseSnackbar = (): void => setIsSnackbarOpen(false);

  const handleDateChange = async (key: string, date: Date | null): Promise<void> => {
    if (date instanceof Date && !isNaN(date.valueOf())) {
      await setRange({
        ...range,
        [key]: date,
      });
    }
  };

  const overheadChangeHandler = (event: ChangeEvent<HTMLInputElement>): void => {
    overheadActions.setOverhead({
      overheadType: editableCustomType,
      value: Number(event.target.value),
    });
  };

  const targetMarginColorStyle = useColorChange(Number(calc.targetMargin), colorChangeOptions);
  const budgetWithoutContingencyColorStyle = useColorChange(Number(calc.budgetWithoutContingency), colorChangeOptions);
  const contingencyColorStyle = useColorChange(Number(calc.contingency), colorChangeOptions);
  const budgetWithContingencyColorStyle = useColorChange(Number(calc.budgetWithContingency), colorChangeOptions);
  const overheadColorStyle = useColorChange(Number(overhead.value), colorChangeOptions);
  const calculateTheSpentBudgetColorStyle = useColorChange(values.calculateTheSpentBudget, colorChangeOptions);
  const availableBudgetColorStyle = useColorChange(calc.availableBudget, colorChangeOptions);

  const totalHoursColorStyle = useColorChange(calc.totalHours, colorChangeOptions);
  const totalSalaryColorStyle = useColorChange(calc.totalSalary, colorChangeOptions);
  const estimateOverheadColorStyle = useColorChange(calc.estimateOverhead, colorChangeOptions);
  const estimateBudgetColorStyle = useColorChange(calc.estimateBudget, colorChangeOptions);
  const estimateCostOfHourColorStyle = useColorChange(calc.estimateCostOfBillableHour, colorChangeOptions);

  const availableBudgetHoursColorStyle = useColorChange(calc.availableBudgetHours, colorChangeOptions);
  const remainingHoursWeCanGiveAwayHoursColorStyle = useColorChange(
    calc.remainingHoursWeCanGiveAwayHours,
    colorChangeOptions,
  );
  const remainingHoursWeCanGiveAwayColorStyle = useColorChange(calc.remainingHoursWeCanGiveAway, colorChangeOptions);
  const hoursWeCanGiveAwayColorStyle = useColorChange(calc.hoursWeCanGiveAway, colorChangeOptions);

  const finalAGMSumWithContingencyColorStyle = useColorChange(calc.finalAGMWithContingency, colorChangeOptions);
  const finalAGMSumWithoutContingencyColorStyle = useColorChange(calc.finalAGMWithoutContingency, colorChangeOptions);
  const finalAGMWithContingencyColorStyle = useColorChange(calc.finalAGMSumWithContingency, colorChangeOptions);
  const finalAGMWithoutContingencyColorStyle = useColorChange(calc.finalAGMSumWithoutContingency, colorChangeOptions);

  return (
    <>
      <form className={classes.root} noValidate autoComplete="off" ref={formReference}>
        <Typography variant="h6" className={classes.header}>
          Settings
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} md={8}>
            <FormControl className={classes.formControl}>
              <Autocomplete
                id="projects"
                size="small"
                openOnFocus
                blurOnSelect
                value={project}
                options={projects}
                getOptionLabel={(option) => option.name}
                renderInput={(parameters) => <TextField {...parameters} label="Project" variant="outlined" />}
                renderOption={({ name, billableType }) => (
                  <>
                    {name}
                    {billableType && (
                      <sup className={classes.span}>&nbsp;{projectBillableTypes[`${billableType}`]}</sup>
                    )}
                  </>
                )}
                onChange={async (_event, value) => value && changeProjectHandler(value)}
              />
            </FormControl>

            <FormControl className={classes.formControl}>
              <NumberFormat
                className={cn(classes.condensed, classes.small)}
                customInput={TextField}
                thousandSeparator
                variant="outlined"
                id="revenue"
                label="S&F Revenue"
                defaultValue={values.revenue}
                InputProps={{
                  startAdornment: <InputAdornment position="start">$</InputAdornment>,
                }}
                onValueChange={({ floatValue }) => changeHandler({ code: 'revenue', value: floatValue })}
              />
            </FormControl>

            <FormControl className={classes.formControl}>
              <NumberFormat
                className={cn(classes.condensed, classes.small)}
                customInput={TextField}
                variant="outlined"
                id="targetProfitability"
                label="Target profitability"
                defaultValue={values.targetProfitability}
                InputProps={{
                  endAdornment: <InputAdornment position="end">%</InputAdornment>,
                }}
                onChange={(event: ChangeEvent<HTMLInputElement>) =>
                  changeHandler({ code: 'targetProfitability', value: Number(event.target.value) })
                }
              />
            </FormControl>
            <FormControl className={classes.formControl}>
              <NumberFormat
                className={cn(classes.condensed, classes.small)}
                customInput={TextField}
                variant="outlined"
                id="referralFee"
                label="Referral fee"
                defaultValue={values.referralFee}
                InputProps={{
                  endAdornment: <InputAdornment position="end">%</InputAdornment>,
                }}
                onChange={(event: ChangeEvent<HTMLInputElement>) =>
                  changeHandler({ code: 'referralFee', value: Number(event.target.value) })
                }
              />
            </FormControl>
            <FormControl className={classes.formControl}>
              <NumberFormat
                className={cn(classes.condensed, classes.small)}
                customInput={TextField}
                variant="outlined"
                id="contingency"
                label="Contingency"
                defaultValue={values.contingency}
                InputProps={{
                  endAdornment: <InputAdornment position="end">%</InputAdornment>,
                }}
                onValueChange={({ floatValue }) => changeHandler({ code: 'contingency', value: floatValue })}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} md={4}>
            {project.id ? (
              <>
                <Typography variant="h6" className={classes.projectName}>
                  {project.name}
                </Typography>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <Grid container spacing={1}>
                    <Grid item xs={12} md={6}>
                      <KeyboardDatePicker
                        autoOk
                        clearable
                        disableFuture
                        inputVariant="outlined"
                        variant="dialog"
                        size="small"
                        label="From"
                        value={range.from}
                        minDate={syncStartDate || undefined}
                        format="MM/dd/yyyy"
                        onChange={(date) => handleDateChange('from', date)}
                        ToolbarComponent={CalendarToolbar}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <KeyboardDatePicker
                        clearable
                        autoOk
                        disableFuture
                        inputVariant="outlined"
                        variant="dialog"
                        size="small"
                        label="To"
                        value={range.to}
                        format="MM/dd/yyyy"
                        onChange={(date) => handleDateChange('to', date)}
                        ToolbarComponent={CalendarToolbar}
                      />
                    </Grid>
                  </Grid>
                </MuiPickersUtilsProvider>
                {minimumDate && (
                  <p>
                    <a
                      href={'/tools/settings'}
                      style={{ textDecoration: 'none', color: `${theme.palette.primary.main}` }}
                    >
                      <strong> Minimal Date is: </strong>
                      {minimumDate}
                    </a>
                  </p>
                )}
              </>
            ) : (
              <TextField
                required
                value={newProjectName}
                autoFocus
                fullWidth
                size="small"
                variant="outlined"
                label="New Project Name"
                onChange={changeNewProjectName}
              />
            )}
            <Divider className={classes.divider} />
            <ul className={classes.calcInfo}>
              <li>
                <strong>Target Margin: </strong>
                <ShowNumber number={calc.targetMargin} prefix="$" style={targetMarginColorStyle} />
              </li>
              <li>
                <strong>Budget without contingency: </strong>
                <ShowNumber
                  number={calc.budgetWithoutContingency}
                  prefix="$"
                  style={budgetWithoutContingencyColorStyle}
                />
              </li>
              <li>
                <strong>Contingency: </strong>
                <ShowNumber number={calc.contingency} prefix="$" style={contingencyColorStyle} />
              </li>
              <li>
                <strong>Budget prior to starting the work: </strong>
                <ShowNumber number={calc.budgetWithContingency} prefix="$" style={budgetWithContingencyColorStyle} />
              </li>
              <li>
                <strong>Calculate the spent budget: </strong>
                <ShowNumber
                  number={values.calculateTheSpentBudget}
                  prefix="$"
                  style={calculateTheSpentBudgetColorStyle}
                />
              </li>
              <li>
                <strong>Available budget: </strong>
                <ShowNumber number={calc.availableBudget} prefix="$" style={availableBudgetColorStyle} />
              </li>
            </ul>
          </Grid>
        </Grid>

        <Typography variant="h6">Team</Typography>

        <Grid container spacing={2}>
          <Grid item xs={12} md={8}>
            <TeamMembers
              team={calc.team}
              employees={employees}
              onDeleteTeamMember={teamActions.deleteTeamMember}
              onChangeRole={teamActions.changeRole}
              onChangeEmployee={teamActions.changeEmployee}
              onChangeHours={teamActions.setHours}
              onChangeCustomRate={teamActions.setRoleCustomRate}
              onRemoveCustomRate={teamActions.removeRoleCustomRate}
              totalHours={calc.totalHours}
              totalSalary={calc.totalSalary}
              experiences={experiences}
              precision={calculatorPrecision}
            />
            <div className={cn(classes.formControl, classes.addTeamMemberButton)}>
              <Button onClick={teamActions.addTeamMember}>Add team member</Button>
            </div>
          </Grid>
          <Grid item xs={12} md={4}>
            <ul className={classes.calcInfo}>
              <li>
                <strong>Overhead/Hour: </strong>
                <Editable
                  style={overheadColorStyle}
                  text={`$${Number(overhead.value)
                    .toFixed(2)
                    .replace(/\\d(?=(\\d{3})+\\.)/g, '$&,')}`}
                  type="input"
                  childRef={overheadInputReference}
                  hint={
                    overhead.type === editableCustomType && (
                      <Button onClick={fetchOverhead} color="primary">
                        Get Overhead from the Config
                      </Button>
                    )
                  }
                >
                  <input
                    className={classes.overheadInput}
                    ref={overheadInputReference}
                    type="text"
                    placeholder="Overhead"
                    defaultValue={overhead.value}
                    onChange={overheadChangeHandler}
                  />
                </Editable>
              </li>
              <li>
                <strong>Estimate Hours: </strong>
                <ShowNumber number={calc.totalHours} style={totalHoursColorStyle} />
              </li>
              <li>
                <strong>Estimate Delivery Salary: </strong>
                <ShowNumber number={calc.totalSalary} prefix="$" style={totalSalaryColorStyle} />
              </li>
              <li>
                <strong>Estimate Overhead: </strong>
                <ShowNumber number={calc.estimateOverhead} prefix="$" style={estimateOverheadColorStyle} />
              </li>
              <li>
                <strong>Estimate Budget: </strong>
                <ShowNumber number={calc.estimateBudget} prefix="$" style={estimateBudgetColorStyle} />
              </li>
              <li>
                <strong>Estimate Cost of hour: </strong>
                <ShowNumber number={calc.estimateCostOfBillableHour} prefix="$" style={estimateCostOfHourColorStyle} />
              </li>
            </ul>

            <Table size="small" aria-label="a dense table">
              <TableHead>
                <TableRow className={classes.tableHeader}>
                  <TableCell />
                  <TableCell align="right">Hours</TableCell>
                  <TableCell align="right">Salary</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Available budget, hours
                  </TableCell>
                  <TableCell align="right">
                    <ShowNumber number={calc.availableBudgetHours} style={availableBudgetHoursColorStyle} />
                  </TableCell>
                  <TableCell />
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Remaining hours we can give away and still hit the target
                  </TableCell>
                  <TableCell align="right">
                    <ShowNumber
                      number={calc.remainingHoursWeCanGiveAwayHours}
                      style={remainingHoursWeCanGiveAwayHoursColorStyle}
                    />
                  </TableCell>
                  <TableCell align="right">
                    <ShowNumber
                      number={calc.remainingHoursWeCanGiveAway}
                      prefix="$"
                      style={remainingHoursWeCanGiveAwayColorStyle}
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Hours we can give away and still hit $0
                  </TableCell>
                  <TableCell align="right">
                    <ShowNumber number={calc.hoursWeCanGiveAway} style={hoursWeCanGiveAwayColorStyle} />
                  </TableCell>
                  <TableCell />
                </TableRow>

                <TableRow className={classes.tableHeader}>
                  <TableCell>Final data</TableCell>
                  <TableCell>With Contingency </TableCell>
                  <TableCell>Without Contingency</TableCell>
                </TableRow>

                <TableRow>
                  <TableCell component="th" scope="row">
                    Project Margin, $
                  </TableCell>
                  <TableCell align="center">-</TableCell>
                  <TableCell align="right">
                    <ShowNumber
                      number={calc.projectMarginWithoutContingency}
                      prefix="$"
                      style={finalAGMSumWithoutContingencyColorStyle}
                    />
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell component="th" scope="row">
                    Project margin, %
                  </TableCell>
                  <TableCell align="center">-</TableCell>
                  <TableCell align="right">
                    <ShowNumber
                      number={calc.projectMarginPercentWithoutContingency}
                      suffix="%"
                      style={finalAGMSumWithoutContingencyColorStyle}
                    />
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell component="th" scope="row">
                    Final Company AGM, $
                  </TableCell>
                  <TableCell align="right">
                    <ShowNumber
                      number={calc.finalAGMSumWithContingency}
                      prefix="$"
                      style={finalAGMSumWithContingencyColorStyle}
                    />
                  </TableCell>
                  <TableCell align="right">
                    <ShowNumber
                      number={calc.finalAGMSumWithoutContingency}
                      prefix="$"
                      style={finalAGMSumWithoutContingencyColorStyle}
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Final Company AGM, %
                  </TableCell>
                  <TableCell align="right">
                    <ShowNumber
                      number={calc.finalAGMWithContingency}
                      suffix="%"
                      style={finalAGMWithContingencyColorStyle}
                    />
                  </TableCell>
                  <TableCell align="right">
                    <ShowNumber
                      number={calc.finalAGMWithoutContingency}
                      suffix="%"
                      style={finalAGMWithoutContingencyColorStyle}
                    />
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Grid>
        </Grid>

        <div className={cn(classes.formControl, classes.reportButton)}>
          <Button color="primary" variant="contained" fullWidth onClick={generateReport}>
            Export to Google sheet
          </Button>
        </div>
      </form>

      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={isSnackbaropen}
        onClose={handleCloseSnackbar}
      >
        <ReturnMessage handleClose={handleCloseSnackbar} reportLink={reportLink} status={status} />
      </Snackbar>
    </>
  );
};
// @ts-ignore
FixBidCalculator.whyDidYouRender = true;
