import { Alert } from '@mui/material';
import NextLink from 'next/link';

export const ReturnMessage = (props: { handleClose: () => void; reportLink: string; status: number }): JSX.Element => {
  const { handleClose, reportLink, status } = props;
  const handleCloseSnackbar = (): void => handleClose();

  if (status === 403) {
    return (
      <Alert severity="error" elevation={6} variant="filled" onClose={handleCloseSnackbar}>
        <div>The Calculation hasn&apos;t been saved.</div>
      </Alert>
    );
  }

  return (
    <Alert severity="success" elevation={6} variant="filled" onClose={handleCloseSnackbar}>
      <div>The Information has been saved.</div>
      <NextLink href={reportLink}>
        <a target="_blank" style={{ color: 'white' }}>
          {reportLink}
        </a>
      </NextLink>
    </Alert>
  );
};
