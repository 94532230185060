import { useReducer } from 'react';
import { v4 as uuid } from 'uuid';

import { FixBidCalculatorConfig } from '../../config';
import {
  ADD_MEMBER,
  CHANGE_EMPLOYEE,
  CHANGE_ROLE,
  DELETE_MEMBER,
  REMOVE_ROLE_CUSTOM_RATE,
  SET_HOURS,
  SET_MEMBER_RATE,
  SET_RATE_TYPE,
  SET_ROLE_CUSTOM_RATE,
} from './consts';
import { Reducer } from './reducer';

export const defaultState: Types.Store.Team.TeamState = [];

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const useTeamStore = (initialState = defaultState) => {
  const [state, dispatch] = useReducer(Reducer, initialState);

  const addTeamMember = (): void => {
    const { role, control } = FixBidCalculatorConfig.teamRoles[0];

    dispatch({
      type: ADD_MEMBER,
      teamMember: {
        id: uuid(),
        role,
        control,
        hours: { billable: 0 },
      },
    });
  };

  const setMemberRate = <T extends Types.Store.Team.SetMemberRateProperties>({
    teamMemberId,
    teamMemberRate,
  }: T): void =>
    dispatch({
      type: SET_MEMBER_RATE,
      teamMemberId,
      teamMemberRate,
    });

  const setRateType = <T extends Types.Store.Team.SetRateTypeProperties>({ rateType, teamMemberId }: T): void =>
    dispatch({
      type: SET_RATE_TYPE,
      rateType,
      teamMemberId,
    });

  const deleteTeamMember = <T extends Types.Store.Team.DeleteMemberProperties>({ teamMemberId }: T): void =>
    dispatch({
      type: DELETE_MEMBER,
      teamMemberId,
    });

  const changeRole = <T extends Types.Store.Team.ChangeRoleProperties>({
    teamRoleId,
    role,
    defaultEmployee,
  }: T): void =>
    dispatch({
      type: CHANGE_ROLE,
      teamRoleId,
      role,
      defaultEmployee,
    });

  const changeEmployee = <T extends Types.Store.Team.ChangeEmployeeProperties>({ teamRoleId, employee }: T): void =>
    dispatch({
      type: CHANGE_EMPLOYEE,
      teamRoleId,
      employee,
    });

  const setRoleCustomRate = <T extends Types.Store.Team.SetRoleCustomRateProperties>({
    teamRoleId,
    teamRoleRate,
  }: T): void =>
    dispatch({
      type: SET_ROLE_CUSTOM_RATE,
      teamRoleId,
      teamRoleRate,
    });

  const setHours = <T extends Types.Store.Team.SetHoursProperties>({ teamRoleId, part, hours }: T): void =>
    dispatch({
      type: SET_HOURS,
      teamRoleId,
      part,
      hours,
    });

  const removeRoleCustomRate = <T extends Types.Store.Team.RemoveRoleCustomRateProperties>({ teamRoleId }: T): void =>
    dispatch({
      type: REMOVE_ROLE_CUSTOM_RATE,
      teamRoleId,
    });

  return {
    actions: {
      addTeamMember,
      setMemberRate,
      deleteTeamMember,
      changeRole,
      changeEmployee,
      setRoleCustomRate,
      setRateType,
      setHours,
      removeRoleCustomRate,
    },
    state,
  };
};
