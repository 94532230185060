import ProgressBar from '@badrap/bar-of-progress';
import DateFnsUtils from '@date-io/date-fns';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import InputAdornment from '@material-ui/core/InputAdornment';
import Snackbar from '@material-ui/core/Snackbar';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { convertDate } from '@server/utils';
import cn from 'classnames';
import { ReturnMessage } from 'components/common/ReturnMessage';
import update from 'immutability-helper';
import get from 'lodash.get';
import { useSession } from 'next-auth/client';
import { SnackbarOrigin, useSnackbar } from 'notistack';
import React, { ChangeEvent, FC, useEffect, useMemo, useReducer, useRef, useState } from 'react';
import NumberFormat from 'react-number-format';
import useColorChange from 'use-color-change';

import {
  colorChangeOptions,
  defaultProgressBarOptions,
  editableCustomType,
  editableOriginalType,
  employeeHistoryRegex,
  Roles,
  SALARY_TYPE_V1,
  SALARY_TYPE_V2,
} from '../../config';
import { getDefaultproject, getDefaultTeam } from '../../config/targetCaclulatorForCurrentProjectDefaultTeam';
import useSocket from '../../hooks/useSocket';
import { projectReducer } from '../../reducers/projectReducer';
import { useOverheadStore } from '../../store/overhead/action';
import { useTeamStore } from '../../store/team/action';
import theme from '../../theme';
import { Project, RateType } from '../../types/components/TeamMembers';
import {
  checkNullAndInfinity,
  floatInputValue,
  forcedSignOut,
  getLastHistoryValue,
  isNumeric,
  projectBillableTypes,
  round,
} from '../../utils';
import { Editable, ShowNumber, TeamMembers } from '../common';
import CalendarToolbar from '../common/CalendarToolbar/CalendarToolbar';
import { useStyles } from './styles';

const anchorOrigin: SnackbarOrigin = { vertical: 'bottom', horizontal: 'left' };

type RType = keyof typeof RateType;

export const TargetCalculatorForCurrentProject: FC<
  Types.Components.TargetCalculatorForCurrentProject.TargetCalculatorForCurrentProjectProperties
> = ({
  projects,
  employees: defaultEmployees,
  overhead: defaultOverhead,
  experiences: defaultExperiences,
  isStaging,
  syncStartDate,
}) => {
  let fetchProjectTimer: number;
  const progress = new ProgressBar(defaultProgressBarOptions);
  const { enqueueSnackbar } = useSnackbar();
  const overheadInputReference = useRef(null);
  const paidBonusesInputReference = useRef(null);
  const formReference = useRef(null);
  const classes = useStyles();
  const [employees, setEmployees] = useState(defaultEmployees);
  const [experiences, setExperiences] = useState(defaultExperiences);

  const snackbarOptions = {
    anchorOrigin,
    autoHideDuration: 30000,
    preventDuplicate: false,
    className: classes.snackbar,
  };

  const [customPaidBonuses, setCustomPaidBonuses] = useState('');
  const [reportLink, setReportLink] = useState('');
  const [status, setStatus] = useState(0);
  const [isSnackbaropen, setIsSnackbaropen] = useState(false);
  const [values, setValues] = useState({
    spentBillableHours: 1000,
    absorbedHours: 100,
    projectRateEU: 15,
    projectRateUS: 20,
    receivedRevenue: 0,
    paidSalary: 0,
    projectMargin: 0,
    projectMarginPercent: 0,
    overhead: 0,
    targetProfitability: 10,
    referralFee: 0,
    contingency: 10,
    paidReferral: 0,
  });
  const defaultFrom = syncStartDate ? new Date(syncStartDate) : null;
  const minimumDate = defaultFrom && `${defaultFrom.toLocaleString('en-US').split(',')[0]}`;
  const defaultTo = new Date();
  const [session] = useSession();
  const [range, setRange] = useState<{
    from: Date | null;
    to: Date | null;
  }>({
    from: defaultFrom,
    to: defaultTo,
  });

  forcedSignOut(status);

  const resetCustomPaidBonuses = (): void => setCustomPaidBonuses('');

  const calculatorPrecision = isStaging ? 4 : 2;
  const userRole: string = session?.user?.roles ? session.user.roles[0] : '';
  const isDMorAdmin = [Roles.Admin, Roles.DepManager].includes(userRole as Roles);

  const fetchExperienceRates = async (): Promise<void> => {
    const result = await fetch(`/api/experienceRates`);

    forcedSignOut(result.status);

    const data = await result.json();

    setExperiences(data);
  };

  const defaultTeam = getDefaultTeam(isStaging, employees, isDMorAdmin, defaultExperiences);
  const { state: team, actions: teamActions } = useTeamStore(defaultTeam);
  const { state: overhead, actions: overheadActions } = useOverheadStore(defaultOverhead);

  const fetchOverhead = async (): Promise<void> => {
    const month = new Date().toISOString().slice(0, 7);
    const result = await fetch(`/api/overheads/${month}`);

    forcedSignOut(result.status);
    const data = await result.json();

    return overheadActions.setOverhead({
      overheadType: editableOriginalType,
      value: get(data, 'overhead', 0),
    });
  };

  const defaultProject = getDefaultproject(isStaging, projects);

  const [projectStore, dispatchProject] = useReducer(projectReducer, {
    project: defaultProject,
    spentBudgetTimestamp: new Date(),
  });

  const { project, spentBudgetTimestamp } = projectStore;

  useEffect(() => {
    const abortController = new AbortController();
    const { signal } = abortController;

    const fetchSpentBudget = async (): Promise<null | undefined> => {
      try {
        if (!project.id) {
          return null;
        }
        progress.start();
        const from = range.from && !isNaN(range.from.valueOf()) && `from=${convertDate(range.from)}`;
        const to = range.to && !isNaN(range.to.valueOf()) && `to=${convertDate(range.to)}`;
        const query = [from, to].filter(Boolean).join('&');
        const result = await fetch(`/api/projects/${project.id}?${query}`, {
          signal,
        });

        forcedSignOut(result.status);
        const data = await result.json();
        const {
          spentBudget,
          salary,
          overhead: overheadValue,
          billableHours,
          nonBillableHours,
          revenue,
          paidReferral,
        } = data || {};

        progress.finish();

        setValues((currentValues) => ({
          ...currentValues,
          calculateTheSpentBudget: round(Number(spentBudget)),
          spentBillableHours: round(Number(billableHours)),
          absorbedHours: round(Number(nonBillableHours)),
          receivedRevenue: round(Number(revenue)),
          paidSalary: round(Number(salary)),
          projectMargin: round(Number(revenue) - Number(paidReferral) - Number(salary)),
          projectMarginPercent:
            Number(revenue) !== 0
              ? round(((Number(revenue) - Number(paidReferral) - Number(salary)) / Number(revenue)) * 100)
              : 0,
          overhead: round(Number(overheadValue)),
          paidReferral: round(Number(paidReferral)),
        }));
      } catch (error) {
        if (!signal.aborted) {
          console.error(error);
        }
      }
    };

    fetchSpentBudget();

    return () => {
      abortController.abort();
      progress.finish();
    };
  }, [project, range, spentBudgetTimestamp]);

  useSocket('realtime', async (message: Types.Controllers.Watchers.Messages) => {
    const { entity, action } = message;

    if (entity === 'projects') {
      const {
        project: { name, id: redmineId },
      } = message as Types.Controllers.Watchers.ProjectMessage;

      if (project.id === redmineId) {
        dispatchProject({ type: 'FETCH_SPENT_BUDGET' });
        enqueueSnackbar(`Project "${name}" has been updated`, snackbarOptions);
      }
    }

    if (entity === 'employees') {
      const {
        employee: { id: teamMemberId, name, history: historyObject },
        updateDescription: updatedFields,
        affectedProjects,
        isUSBased,
        isExperienceOrUSBasedChanged,
      } = message as Types.Controllers.Watchers.EmployeeMessage;
      const history = Object.entries(updatedFields)
        .filter(([key]) => key.match(employeeHistoryRegex))
        .map(([key, value]) => {
          const [, entityName, date] = key.match(employeeHistoryRegex) || [];

          return `The "${entityName}" parameter has been set to ${value} since ${date}.`;
        })
        .join(' and ');

      const salaryType = getLastHistoryValue(historyObject.salaryType, false);
      const rate = getLastHistoryValue(historyObject.rate, false);
      const rate2 = getLastHistoryValue(historyObject.rate2, false);
      const stake = getLastHistoryValue(historyObject.stake, false);
      const employmentType = getLastHistoryValue(historyObject.employmentType, false);

      const teamMemberRate =
        salaryType === SALARY_TYPE_V1 ? Number(rate) : Number(rate2) + Number(stake) / (160 * Number(employmentType));

      const employeeIndex = employees.findIndex(({ id }) => id === teamMemberId);

      if (employeeIndex >= 0) {
        setEmployees((_employees) =>
          update(_employees, {
            [employeeIndex]: (member) =>
              update(member, {
                rate: { $set: Number(teamMemberRate) },
              }),
          }),
        );
      }

      teamActions.setMemberRate({ teamMemberId, teamMemberRate });

      const hasExperience =
        isExperienceOrUSBasedChanged ||
        isUSBased ||
        (historyObject.experience && Object.keys(historyObject.experience).length > 0);

      if (hasExperience) {
        fetchExperienceRates();
      }

      if (affectedProjects?.includes(project.id)) {
        dispatchProject({ type: 'FETCH_SPENT_BUDGET' });
      }

      enqueueSnackbar(`Employee "${name}" has been updated.\n${history}`, snackbarOptions);
    }

    if (entity === 'overheads') {
      const { from, to } = range;
      const { month, value } = message as Types.Controllers.Watchers.OverheadMessage;
      const isMonthActual =
        (from === null || new Date(from).getTime() <= new Date(`${month}T00:00:00`).getTime()) &&
        (to === null || new Date(to).getTime() >= new Date(`${month}T00:00:00`).getTime());

      if (isMonthActual) {
        if (action === 'delete') {
          enqueueSnackbar(`${month} overhead has been deleted.`, snackbarOptions);

          await fetchOverhead();
        }

        overheadActions.setOverhead({
          value: Number(value),
        });

        dispatchProject({ type: 'FETCH_SPENT_BUDGET' });

        enqueueSnackbar(`Overhead has been  has been set to ${value} since ${month}.`, snackbarOptions);
      }
    }

    if (entity === 'timeEntries') {
      const projectId = get(message, 'projectId');

      if (project.id === projectId) {
        if (fetchProjectTimer) {
          window.clearTimeout(fetchProjectTimer);
        }

        fetchProjectTimer = window.setTimeout(async () => {
          dispatchProject({ type: 'FETCH_SPENT_BUDGET' });
          enqueueSnackbar(`The spent budget has been updated`, snackbarOptions);
        }, 2000);
      }
    }
  });

  const changeHandler = async ({ code, value }: { code: string; value: unknown }): Promise<void> => {
    try {
      setValues({ ...values, [code]: value || 0 });
    } catch (error) {
      console.error(error);
    }
  };

  const changeProjectHandler = async (projectData: Project): Promise<void> =>
    dispatchProject({
      type: 'CHANGE_PROJECT',
      project: projectData,
    });

  const calc = useMemo(() => {
    values.contingency = values.contingency || 0;

    const teamData = team.map(
      ({
        employee,
        role,
        customRate,
        hours: { billable = 0, absorbed = 0 },
        rateType = RateType.euBillableHours,
        ...rest
      }) => {
        const salaryRate = isNumeric(`${customRate}`) ? customRate : experiences[role];
        const total = billable + absorbed;

        let billableSalary = billable * Number(salaryRate);
        let gtSalary = absorbed * Number(salaryRate);

        if (employee) {
          const { salaryType, rateV1, rateV2, stake, employmentType } = employee || {};

          billableSalary = billable * Number(salaryType === SALARY_TYPE_V1 ? rateV1 : rateV2);
          gtSalary = absorbed * Number(salaryType === SALARY_TYPE_V1 ? rateV1 : rateV2);

          if (salaryType === SALARY_TYPE_V2) {
            const stakePart = Number(stake) / (160 * Number(employmentType));

            billableSalary += billable * stakePart;
            gtSalary += absorbed * stakePart;
          }
        }

        const salary = employee ? Number(employee.rate) * Number(total) : Number(salaryRate || 0) * Number(total);

        return {
          role,
          rate: experiences[role] || 0,
          customRate,
          hours: {
            billable,
            absorbed,
            total,
          },
          employee,
          billableSalary,
          gtSalary,
          salary,
          rateType,
          ...rest,
        };
      },
    );

    const effectiveRate = values.receivedRevenue / (values.spentBillableHours + values.absorbedHours);
    const agm = values.receivedRevenue - values.paidReferral - values.paidSalary - values.overhead;
    const profit = agm - Number(customPaidBonuses);

    const { usBillableHours, euBillableHours, absorbedHours, totalHours } = team.reduce(
      (accumulator, { hours: { billable = 0, absorbed = 0 }, rateType = RateType.euBillableHours }) => {
        accumulator[rateType as RType] += billable;
        accumulator.absorbedHours += absorbed;
        accumulator.totalHours += billable + absorbed;

        return accumulator;
      },
      {
        [RateType.usBillableHours]: 0,
        [RateType.euBillableHours]: 0,
        absorbedHours: 0,
        totalHours: 0,
      },
    );

    const { totalBillableSalary, totalGTSalary } = teamData.reduce(
      (accumulator, { billableSalary, gtSalary }) => {
        accumulator.totalBillableSalary += billableSalary || 0;
        accumulator.totalGTSalary += gtSalary || 0;

        return accumulator;
      },
      {
        totalBillableSalary: 0,
        totalGTSalary: 0,
      },
    );

    const phaseRevenue = euBillableHours * values.projectRateEU + usBillableHours * values.projectRateUS;
    const phaseEffectiveRate = phaseRevenue / totalHours;
    const targetMargin = (phaseRevenue * values.targetProfitability) / 100;
    const budgetWithoutContingency = phaseRevenue - targetMargin - (phaseRevenue * values.referralFee) / 100;
    const contingency = (budgetWithoutContingency * values.contingency) / 100;
    const budgetWithContingency = budgetWithoutContingency - contingency; // [Budget without contingency - Contingency, $]

    const totalSalary = teamData.reduce((accumulator, { salary }) => {
      return accumulator + salary;
    }, 0);
    const billableHours = euBillableHours + usBillableHours;
    const euRateRevenue = euBillableHours * values.projectRateEU;
    const usRateRevenue = usBillableHours * values.projectRateUS;
    const revenue = euRateRevenue + usRateRevenue;
    const estimateOverhead = billableHours * Number(overhead.value);
    const estimateBudget = totalBillableSalary + totalGTSalary + estimateOverhead;

    const estimateCostOfBillableHour = (totalBillableSalary + estimateOverhead) / billableHours;
    const estimateCostOfGTHour = totalGTSalary / absorbedHours;

    const remainingBudget = budgetWithContingency - estimateBudget;

    const hoursWeCanGiveAwayAndHitTarget = remainingBudget / estimateCostOfGTHour;
    const hoursWeCanGiveAwayAndHitZero =
      (profit +
        (phaseRevenue - (phaseRevenue * values.referralFee) / 100 - (phaseRevenue * values.contingency) / 100) -
        estimateBudget) /
      estimateCostOfGTHour;

    // const effectiveRate = revenue / totalHours; //OLD

    // [Target Margin, $] = [S&F Revenue* Target profitability, %]

    const remainingHoursWeCanGiveAway = budgetWithContingency - estimateBudget; // [Budget with contingency, $-Estimate Budget, $]
    const remainingHoursWeCanGiveAwayHours = remainingHoursWeCanGiveAway / estimateCostOfBillableHour; // [Remaining hours we can give away and still hit the target, $/Estimate Cost of hour, $]
    const hoursWeCanGiveAway =
      (revenue - (revenue * values.referralFee) / 100 - contingency - estimateBudget) / estimateCostOfBillableHour;
    // [S&F Revenue-S&F Revenue*Referral fee-Contingency, $-Calculate the spent budget, $-Estimate Budget, $]

    const currentProjectMarginWithoutContingency =
      phaseRevenue - (phaseRevenue * values.referralFee) / 100 - totalBillableSalary - totalGTSalary;
    const currentAGMSumWithoutContingency = phaseRevenue - (phaseRevenue * values.referralFee) / 100 - estimateBudget;
    const currentAGMSumWithContingency = currentAGMSumWithoutContingency - contingency;
    let currentAGMWithoutContingency = 0;
    let currentAGMWithContingency = 0;
    let currentProjectMarginPercentWithoutContingency = 0;

    if (phaseRevenue !== 0) {
      currentAGMWithContingency = (currentAGMSumWithContingency / phaseRevenue) * 100;
      currentAGMWithoutContingency = (currentAGMSumWithoutContingency / phaseRevenue) * 100;
      currentProjectMarginPercentWithoutContingency = (currentProjectMarginWithoutContingency / phaseRevenue) * 100;
    }

    const finalAGMSumWithoutContingency =
      profit + phaseRevenue - (phaseRevenue * values.referralFee) / 100 - estimateBudget;
    const finalAGMSumWithContingency = finalAGMSumWithoutContingency - contingency;

    let finalAGMWithContingency = 0;
    let finalAGMWithoutContingency = 0;

    if (revenue + values.receivedRevenue !== 0) {
      finalAGMWithContingency = (finalAGMSumWithContingency / (revenue + values.receivedRevenue)) * 100;
      finalAGMWithoutContingency = (finalAGMSumWithoutContingency / (revenue + values.receivedRevenue)) * 100;
    }

    return {
      effectiveRate: round(effectiveRate),
      agm: round(agm),
      paidBonuses: round(Number(customPaidBonuses)),
      profit: round(profit),

      phaseRevenue: round(phaseRevenue),
      phaseEffectiveRate: round(phaseEffectiveRate),
      targetMargin: round(targetMargin),
      budgetWithoutContingency: round(budgetWithoutContingency),
      contingency: round(contingency),
      budgetWithContingency: round(budgetWithContingency),
      remainingBudget: round(remainingBudget),
      hoursWeCanGiveAwayAndHitTarget: checkNullAndInfinity(hoursWeCanGiveAwayAndHitTarget),
      hoursWeCanGiveAwayAndHitZero: checkNullAndInfinity(hoursWeCanGiveAwayAndHitZero),
      team: teamData,
      billableHours: round(billableHours),
      absorbedHours: round(absorbedHours),
      totalBillableSalary: round(totalBillableSalary),
      totalGTSalary: round(totalGTSalary),

      totalHours: round(totalHours),
      revenue: round(revenue),
      totalSalary: round(totalSalary),
      estimateOverhead: round(estimateOverhead),
      estimateBudget: round(estimateBudget),
      estimateCostOfBillableHour: round(estimateCostOfBillableHour),
      estimateCostOfGTHour: round(estimateCostOfGTHour),
      remainingHoursWeCanGiveAway: round(remainingHoursWeCanGiveAway),
      remainingHoursWeCanGiveAwayHours: round(remainingHoursWeCanGiveAwayHours),
      hoursWeCanGiveAway: round(hoursWeCanGiveAway),
      currentProjectMarginWithoutContingency: round(currentProjectMarginWithoutContingency),
      currentProjectMarginPercentWithoutContingency: round(currentProjectMarginPercentWithoutContingency),
      currentAGMSumWithContingency: round(currentAGMSumWithContingency),
      currentAGMSumWithoutContingency: round(currentAGMSumWithoutContingency),
      currentAGMWithContingency: round(currentAGMWithContingency),
      currentAGMWithoutContingency: round(currentAGMWithoutContingency),

      finalAGMWithContingency: round(finalAGMWithContingency),
      finalAGMSumWithContingency: round(finalAGMSumWithContingency),
      finalAGMSumWithoutContingency: round(finalAGMSumWithoutContingency),
      finalAGMWithoutContingency: round(finalAGMWithoutContingency),
    };
  }, [values, team, overhead.value, experiences, customPaidBonuses]);

  const generateReport = async (): Promise<void> => {
    // @ts-ignore
    const validated = formReference.current.reportValidity();

    if (!validated) {
      return;
    }

    const result = await fetch('/api/calculator/generateTargetCalculatorForCurrentProjectReport', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        range,
        project,
        values,
        calc,
        overhead,
      }),
    });

    const report = await result.json();

    setReportLink(`https://docs.google.com/spreadsheets/d/${report.spreadsheetId}/edit#gid=${report.sheetId}`);
    setStatus(result.status);
    setIsSnackbaropen(true);
  };

  const handleCloseSnackbar = (): void => setIsSnackbaropen(false);

  const handleDateChange = async (key: string, date: Date | null): Promise<void> => {
    if (date instanceof Date && !isNaN(date.valueOf())) {
      await setRange({
        ...range,
        [key]: date,
      });
    }
  };

  const overheadChangeHandler = (event: ChangeEvent<HTMLInputElement>): void =>
    overheadActions.setOverhead({
      overheadType: editableCustomType,
      value: Number(event.target.value),
    });

  const customPaidBonusesChangeHandler = (event: ChangeEvent<HTMLInputElement>): void => {
    const { value } = event.target;

    setCustomPaidBonuses(floatInputValue(value));
  };

  const spentBillableHoursColorStyle = useColorChange(Number(values.spentBillableHours), colorChangeOptions);
  const absorbedHoursColorStyle = useColorChange(Number(values.absorbedHours), colorChangeOptions);
  const receivedRevenueColorStyle = useColorChange(Number(values.receivedRevenue), colorChangeOptions);
  const effectiveRateColorStyle = useColorChange(Number(calc.effectiveRate), colorChangeOptions);
  const paidReferralColorStyle = useColorChange(Number(values.paidReferral), colorChangeOptions);
  const paidSalaryColorStyle = useColorChange(Number(values.paidSalary), colorChangeOptions);
  const overheadColorStyle = useColorChange(Number(values.overhead), colorChangeOptions);
  const agmColorStyle = useColorChange(Number(calc.agm), colorChangeOptions);
  const profitColorStyle = useColorChange(Number(calc.profit), colorChangeOptions);

  const phaseRevenueColorStyle = useColorChange(Number(calc.phaseRevenue), colorChangeOptions);
  const phaseEffectiveRateColorStyle = useColorChange(Number(calc.phaseEffectiveRate), colorChangeOptions);
  const targetMarginColorStyle = useColorChange(Number(calc.targetMargin), colorChangeOptions);
  const budgetWithoutContingencyColorStyle = useColorChange(Number(calc.budgetWithoutContingency), colorChangeOptions);
  const contingencyColorStyle = useColorChange(Number(calc.contingency), colorChangeOptions);
  const budgetWithContingencyColorStyle = useColorChange(Number(calc.budgetWithContingency), colorChangeOptions);
  const remainingBudgetColorStyle = useColorChange(Number(calc.remainingBudget), colorChangeOptions);
  const hoursWeCanGiveAwayAndHitTargetColorStyle = useColorChange(
    Number(calc.hoursWeCanGiveAwayAndHitTarget),
    colorChangeOptions,
  );
  const hoursWeCanGiveAwayAndHitZeroColorStyle = useColorChange(
    Number(calc.hoursWeCanGiveAwayAndHitZero),
    colorChangeOptions,
  );

  const totalHoursColorStyle = useColorChange(Number(calc.totalHours), colorChangeOptions);
  const totalSalaryColorStyle = useColorChange(Number(calc.totalSalary), colorChangeOptions);
  const estimateOverheadColorStyle = useColorChange(Number(calc.estimateOverhead), colorChangeOptions);
  const estimateBudgetColorStyle = useColorChange(Number(calc.estimateBudget), colorChangeOptions);
  const estimateCostOfBillableHourColorStyle = useColorChange(
    Number(calc.estimateCostOfBillableHour),
    colorChangeOptions,
  );
  const estimateCostOfGTHourColorStyle = useColorChange(Number(calc.estimateCostOfGTHour), colorChangeOptions);

  const currentAGMSumWithContingencyColorStyle = useColorChange(
    Number(calc.currentAGMSumWithContingency),
    colorChangeOptions,
  );
  const currentAGMSumWithoutContingencyColorStyle = useColorChange(
    Number(calc.currentAGMSumWithoutContingency),
    colorChangeOptions,
  );
  const currentAGMWithContingencyColorStyle = useColorChange(
    Number(calc.currentAGMWithContingency),
    colorChangeOptions,
  );
  const currentAGMWithoutContingencyColorStyle = useColorChange(
    Number(calc.currentAGMWithoutContingency),
    colorChangeOptions,
  );

  const finalAGMSumWithContingencyColorStyle = useColorChange(
    Number(calc.finalAGMSumWithContingency),
    colorChangeOptions,
  );
  const finalAGMSumWithoutContingencyColorStyle = useColorChange(
    Number(calc.finalAGMSumWithoutContingency),
    colorChangeOptions,
  );
  const finalAGMWithContingencyColorStyle = useColorChange(Number(calc.finalAGMWithContingency), colorChangeOptions);
  const finalAGMWithoutContingencyColorStyle = useColorChange(
    Number(calc.finalAGMWithoutContingency),
    colorChangeOptions,
  );

  return (
    <>
      <form className={classes.root} noValidate autoComplete="off" ref={formReference}>
        <Typography variant="h6" className={classes.header}>
          Settings
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} md={8}>
            <FormControl className={classes.formControl}>
              <Autocomplete
                id="projects"
                size="small"
                openOnFocus
                blurOnSelect
                value={project}
                options={[...projects, defaultProject]}
                getOptionLabel={(option) => option.name}
                getOptionSelected={(option, value) => option.name === value.name}
                renderInput={(parameters) => <TextField {...parameters} label="Project" variant="outlined" />}
                renderOption={({ name, billableType }) => (
                  <>
                    {name}
                    {billableType && (
                      <sup className={classes.span}>&nbsp;{projectBillableTypes[`${billableType}`]}</sup>
                    )}
                  </>
                )}
                onChange={async (_event, value) => value && changeProjectHandler(value)}
              />
            </FormControl>

            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <FormControl className={classes.formControl}>
                  <NumberFormat
                    className={cn(classes.condensed, classes.small)}
                    customInput={TextField}
                    thousandSeparator
                    variant="outlined"
                    id="projectRateEU"
                    label="Phase Rate EU"
                    defaultValue={values.projectRateEU}
                    InputProps={{
                      startAdornment: <InputAdornment position="start">$</InputAdornment>,
                    }}
                    onValueChange={({ floatValue }) => changeHandler({ code: 'projectRateEU', value: floatValue })}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl className={classes.formControl}>
                  <NumberFormat
                    className={cn(classes.condensed, classes.small)}
                    customInput={TextField}
                    thousandSeparator
                    variant="outlined"
                    id="projectRateUS"
                    label="Phase Rate US"
                    defaultValue={values.projectRateUS}
                    InputProps={{
                      startAdornment: <InputAdornment position="start">$</InputAdornment>,
                    }}
                    onValueChange={({ floatValue }) => changeHandler({ code: 'projectRateUS', value: floatValue })}
                  />
                </FormControl>
              </Grid>
            </Grid>

            <FormControl className={classes.formControl}>
              <NumberFormat
                className={cn(classes.condensed, classes.small)}
                customInput={TextField}
                variant="outlined"
                id="targetProfitability"
                label="Target profitability"
                defaultValue={values.targetProfitability}
                InputProps={{
                  endAdornment: <InputAdornment position="end">%</InputAdornment>,
                }}
                onChange={(event: ChangeEvent<HTMLInputElement>) =>
                  changeHandler({ code: 'targetProfitability', value: Number(event.target.value) })
                }
              />
            </FormControl>
            <FormControl className={classes.formControl}>
              <NumberFormat
                className={cn(classes.condensed, classes.small)}
                customInput={TextField}
                variant="outlined"
                id="referralFee"
                label="Phase Referral Fee"
                defaultValue={values.referralFee}
                InputProps={{
                  endAdornment: <InputAdornment position="end">%</InputAdornment>,
                }}
                onChange={(event: ChangeEvent<HTMLInputElement>) =>
                  changeHandler({ code: 'referralFee', value: Number(event.target.value) })
                }
              />
            </FormControl>
            <FormControl className={classes.formControl}>
              <NumberFormat
                className={cn(classes.condensed, classes.small)}
                customInput={TextField}
                variant="outlined"
                id="contingency"
                label="Contingency"
                defaultValue={values.contingency}
                InputProps={{
                  endAdornment: <InputAdornment position="end">%</InputAdornment>,
                }}
                onValueChange={({ floatValue }) => changeHandler({ code: 'contingency', value: floatValue })}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} md={4}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <KeyboardDatePicker
                    autoOk
                    clearable
                    disableFuture
                    inputVariant="outlined"
                    variant="dialog"
                    size="small"
                    label="From"
                    value={range.from}
                    minDate={syncStartDate || undefined}
                    format="MM/dd/yyyy"
                    onChange={(date) => handleDateChange('from', date)}
                    ToolbarComponent={CalendarToolbar}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <KeyboardDatePicker
                    clearable
                    autoOk
                    disableFuture
                    inputVariant="outlined"
                    variant="dialog"
                    size="small"
                    label="To"
                    value={range.to}
                    format="MM/dd/yyyy"
                    onChange={(date) => handleDateChange('to', date)}
                    ToolbarComponent={CalendarToolbar}
                  />
                </Grid>
              </Grid>
            </MuiPickersUtilsProvider>
            {minimumDate && (
              <p>
                <a href={'/tools/settings'} style={{ textDecoration: 'none', color: `${theme.palette.primary.main}` }}>
                  <strong> Minimal Date is: </strong>
                  {minimumDate}
                </a>
              </p>
            )}
            <Divider className={classes.divider} />

            <ul className={classes.calcInfo}>
              <li>
                <strong>Spent billable hours: </strong>
                <ShowNumber number={values.spentBillableHours} style={spentBillableHoursColorStyle} />
              </li>
              <li>
                <strong>Absorbed hours: </strong>
                <ShowNumber number={values.absorbedHours} style={absorbedHoursColorStyle} />
              </li>
              <li>
                <strong>Received Revenue: </strong>
                <ShowNumber number={values.receivedRevenue} prefix="$" style={receivedRevenueColorStyle} />
              </li>
              <li>
                <strong>Effective rate: </strong>
                <ShowNumber number={calc.effectiveRate} prefix="$" style={effectiveRateColorStyle} />
              </li>
              <li>
                <strong>Paid referral: </strong>
                <ShowNumber number={values.paidReferral} prefix="$" style={paidReferralColorStyle} />
              </li>
              <li>
                <strong>Paid Salary: </strong>
                <ShowNumber number={values.paidSalary} prefix="$" style={paidSalaryColorStyle} />
              </li>
              <li>
                <strong>Project Margin: </strong>
                <ShowNumber number={values.projectMargin} prefix="$" style={paidSalaryColorStyle} />
              </li>
              <li>
                <strong>Project margin: </strong>
                <ShowNumber number={values.projectMarginPercent} suffix="%" style={paidSalaryColorStyle} />
              </li>
              <li>
                <strong>Overhead: </strong>
                <ShowNumber number={values.overhead} prefix="$" style={overheadColorStyle} />
              </li>
              <li>
                <strong>AGM: </strong>
                <ShowNumber number={calc.agm} prefix="$" style={agmColorStyle} />
              </li>
              <li>
                <strong>Paid bonuses: </strong>
                <Editable
                  style={overheadColorStyle}
                  text={`$${Number(customPaidBonuses)
                    .toFixed(2)
                    .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}`}
                  type="input"
                  childRef={paidBonusesInputReference}
                  hint={
                    customPaidBonuses && (
                      <Button onClick={resetCustomPaidBonuses} color="primary" size="small">
                        Reset
                      </Button>
                    )
                  }
                  // onClose={displayOverheadMessage}
                >
                  <input
                    className={classes.overheadInput}
                    ref={paidBonusesInputReference}
                    type="text"
                    placeholder="Paid bonuses"
                    value={`${customPaidBonuses}`}
                    onChange={customPaidBonusesChangeHandler}
                  />
                </Editable>
              </li>
              <li>
                <strong>Profit: </strong>
                <ShowNumber number={calc.profit} prefix="$" style={profitColorStyle} />
              </li>
            </ul>
          </Grid>
        </Grid>

        <Typography variant="h6">Team</Typography>

        <Grid container spacing={2}>
          <Grid item xs={12} md={8}>
            <TeamMembers
              team={calc.team}
              employees={employees}
              onDeleteTeamMember={teamActions.deleteTeamMember}
              onChangeRole={teamActions.changeRole}
              onChangeEmployee={teamActions.changeEmployee}
              onChangeHours={teamActions.setHours}
              onChangeCustomRate={teamActions.setRoleCustomRate}
              onRemoveCustomRate={teamActions.removeRoleCustomRate}
              onSetRateType={teamActions.setRateType}
              billableHours={calc.billableHours || 0}
              absorbedHours={calc.absorbedHours || 0}
              totalBillableSalary={calc.totalBillableSalary || 0}
              totalGTSalary={calc.totalGTSalary || 0}
              totalHours={calc.totalHours}
              totalSalary={calc.totalSalary}
              experiences={experiences}
              precision={calculatorPrecision}
              splitSalary
              splitHours
              needsRateType
            />
            <div className={cn(classes.formControl, classes.addTeamMemberButton)}>
              <Button onClick={teamActions.addTeamMember}>Add team member</Button>
            </div>
          </Grid>
          <Grid item xs={12} md={4}>
            <ul className={classes.calcInfo}>
              <li>
                <strong>Overhead/Hour: </strong>
                <Editable
                  style={overheadColorStyle}
                  text={`$${Number(overhead.value)
                    .toFixed(2)
                    .replace(/\\d(?=(\\d{3})+\\.)/g, '$&,')}`}
                  type="input"
                  childRef={overheadInputReference}
                  hint={
                    overhead.type === editableCustomType && (
                      <Button onClick={fetchOverhead} color="primary" size="small">
                        Get Overhead from the Config
                      </Button>
                    )
                  }
                >
                  <input
                    className={classes.overheadInput}
                    ref={overheadInputReference}
                    type="text"
                    placeholder="Overhead"
                    defaultValue={overhead.value}
                    onChange={overheadChangeHandler}
                  />
                </Editable>
              </li>
              <li>
                <strong>Estimate Hours: </strong>
                <span>
                  <ShowNumber number={calc.totalHours} style={totalHoursColorStyle} />
                </span>
              </li>
              <li>
                <strong>Estimate Billable Salary: </strong>
                <ShowNumber number={calc.totalBillableSalary} prefix="$" style={totalSalaryColorStyle} />
              </li>
              <li>
                <strong>Estimated DT Salary: </strong>
                <ShowNumber number={calc.totalGTSalary} prefix="$" style={totalSalaryColorStyle} />
              </li>
              <li>
                <strong>Estimate Overhead: </strong>
                <ShowNumber number={calc.estimateOverhead} prefix="$" style={estimateOverheadColorStyle} />
              </li>
              <li>
                <strong>Estimate Budget: </strong>
                <ShowNumber number={calc.estimateBudget} prefix="$" style={estimateBudgetColorStyle} />
              </li>
              <li>
                <strong>Estimate Cost of Billable hour: </strong>
                <ShowNumber
                  number={calc.estimateCostOfBillableHour}
                  prefix="$"
                  style={estimateCostOfBillableHourColorStyle}
                />
              </li>
              <li>
                <strong>Estimate Cost of DT hour: </strong>
                <ShowNumber number={calc.estimateCostOfGTHour} prefix="$" style={estimateCostOfGTHourColorStyle} />
              </li>
            </ul>
            <ul className={classes.calcInfo}>
              <li>
                <strong>Phase Revenue: </strong>
                <ShowNumber number={calc.phaseRevenue} prefix="$" style={phaseRevenueColorStyle} />
              </li>
              <li>
                <strong>Phase Effective Rate: </strong>
                <ShowNumber number={calc.phaseEffectiveRate} prefix="$" style={phaseEffectiveRateColorStyle} />
              </li>
              <li>
                <strong>Target Margin: </strong>
                <ShowNumber number={calc.targetMargin} prefix="$" style={targetMarginColorStyle} />
              </li>
              <li>
                <strong>Budget without contingency: </strong>
                <ShowNumber
                  number={calc.budgetWithoutContingency}
                  prefix="$"
                  style={budgetWithoutContingencyColorStyle}
                />
              </li>
              <li>
                <strong>Contingency: </strong>
                <ShowNumber number={calc.contingency} prefix="$" style={contingencyColorStyle} />
              </li>
              <li>
                <strong>Budget with contingency: </strong>
                <ShowNumber number={calc.budgetWithContingency} prefix="$" style={budgetWithContingencyColorStyle} />
              </li>
              <li>
                <strong>Remaining budget: </strong>
                <ShowNumber number={calc.remainingBudget} prefix="$" style={remainingBudgetColorStyle} />
              </li>
              <li>
                <strong>Hours we can give away and still hit the target: </strong>
                <ShowNumber
                  number={calc.hoursWeCanGiveAwayAndHitTarget}
                  style={hoursWeCanGiveAwayAndHitTargetColorStyle}
                />
              </li>
              <li>
                <strong>Hours we can give away and still hit $0: </strong>
                <ShowNumber number={calc.hoursWeCanGiveAwayAndHitZero} style={hoursWeCanGiveAwayAndHitZeroColorStyle} />
              </li>
            </ul>

            <Table size="small" aria-label="a dense table">
              <TableHead>
                <TableRow className={classes.tableHeader}>
                  <TableCell>Current Phase</TableCell>
                  <TableCell>With Contingency </TableCell>
                  <TableCell>Without Contingency</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Project Margin for current phase, $
                  </TableCell>
                  <TableCell align="center">-</TableCell>
                  <TableCell align="right">
                    <ShowNumber
                      number={calc.currentProjectMarginWithoutContingency}
                      prefix="$"
                      style={currentAGMSumWithoutContingencyColorStyle}
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Project margin for current phase, %
                  </TableCell>

                  <TableCell align="center">-</TableCell>
                  <TableCell align="right">
                    <ShowNumber
                      number={calc.currentProjectMarginPercentWithoutContingency}
                      suffix="%"
                      style={currentAGMSumWithoutContingencyColorStyle}
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    AGM for current phase, $
                  </TableCell>
                  <TableCell align="right">
                    <ShowNumber
                      number={calc.currentAGMSumWithContingency}
                      prefix="$"
                      style={currentAGMSumWithContingencyColorStyle}
                    />
                  </TableCell>
                  <TableCell align="right">
                    <ShowNumber
                      number={calc.currentAGMSumWithoutContingency}
                      prefix="$"
                      style={currentAGMSumWithoutContingencyColorStyle}
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    AGM for current phase, %
                  </TableCell>
                  <TableCell align="right">
                    <ShowNumber
                      number={calc.currentAGMWithContingency}
                      suffix="%"
                      style={currentAGMWithContingencyColorStyle}
                    />
                  </TableCell>
                  <TableCell align="right">
                    <ShowNumber
                      number={calc.currentAGMWithoutContingency}
                      suffix="%"
                      style={currentAGMWithoutContingencyColorStyle}
                    />
                  </TableCell>
                </TableRow>

                <TableRow className={classes.tableHeader}>
                  <TableCell>Final data</TableCell>
                  <TableCell>With Contingency </TableCell>
                  <TableCell>Without Contingency</TableCell>
                </TableRow>

                <TableRow>
                  <TableCell component="th" scope="row">
                    Final AGM, $
                  </TableCell>
                  <TableCell align="right">
                    <ShowNumber
                      number={calc.finalAGMSumWithContingency}
                      prefix="$"
                      style={finalAGMSumWithContingencyColorStyle}
                    />
                  </TableCell>
                  <TableCell align="right">
                    <ShowNumber
                      number={calc.finalAGMSumWithoutContingency}
                      prefix="$"
                      style={finalAGMSumWithoutContingencyColorStyle}
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Final AGM, %
                  </TableCell>
                  <TableCell align="right">
                    <ShowNumber
                      number={calc.finalAGMWithContingency}
                      suffix="%"
                      style={finalAGMWithContingencyColorStyle}
                    />
                  </TableCell>
                  <TableCell align="right">
                    <ShowNumber
                      number={calc.finalAGMWithoutContingency}
                      suffix="%"
                      style={finalAGMWithoutContingencyColorStyle}
                    />
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Grid>
        </Grid>

        <div className={cn(classes.formControl, classes.reportButton)}>
          <Button color="primary" variant="contained" fullWidth onClick={generateReport}>
            Export to Google sheet
          </Button>
        </div>
      </form>

      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={isSnackbaropen}
        onClose={handleCloseSnackbar}
      >
        <ReturnMessage handleClose={handleCloseSnackbar} reportLink={reportLink} status={status} />
      </Snackbar>
    </>
  );
};
// @ts-ignore
TargetCalculatorForCurrentProject.whyDidYouRender = true;
