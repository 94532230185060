import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme & { drawerWidth: number }) => {
  return {
    negative: {
      color: 'red',
    },
    header: {
      marginBottom: theme.spacing(0.5),
    },
    root: {
      display: 'grid',
      gridTemplateColumns: '1fr',
    },
    condensed: {
      '& .MuiOutlinedInput-inputMarginDense, & .MuiSelect-root': {
        padding: theme.spacing(0.85),
      },

      '& label': {
        transform: 'translate(14px, 10px) scale(1)',
      },
    },
    small: {
      '& .MuiInputBase-input': {
        padding: theme.spacing(0.85),
      },
    },
    formControl: {
      marginBottom: theme.spacing(1),
      minWidth: 120,
      display: 'flex',
      position: 'relative',
    },
    reportButton: {
      marginTop: theme.spacing(1.5),
    },
    addTeamMemberButton: {
      marginTop: theme.spacing(1),
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    calcInfo: {
      marginTop: theme.spacing(0),
      listStyleType: 'none',
      paddingLeft: 0,
    },
    divider: {
      margin: '1rem 0',
    },
    speedDial: {
      position: 'absolute',
      bottom: theme.spacing(2),
      right: theme.spacing(2),
    },
    tableHeader: {
      backgroundColor: theme.palette.grey['100'],
    },
    close: {
      padding: theme.spacing(0.5),
    },
    reportlink: {
      color: theme.palette.common.white,
    },
    projectName: {
      marginBottom: theme.spacing(0.5),
    },
    overheadInput: {
      marginTop: 1,
    },
    snackbar: {
      backgroundColor: 'green',
      [theme.breakpoints.up('sm')]: {
        maxWidth: theme.drawerWidth - 10 + 16,
        display: 'grid',
        gridTemplateColumns: `${theme.drawerWidth - 20 - 40}px 40px`,

        minWidth: theme.drawerWidth - 10 + 16,

        '& #notistack-snackbar + div': {
          marginLeft: 0,
          paddingLeft: 0,
        },
      },
    },
    span: {
      color: theme.palette.primary.main,
      fontSize: 'x-small',
    },
  };
});
