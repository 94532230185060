import { Employee } from 'types/components/FixBidCalculator';
import { Project } from 'types/components/TeamMembers';
import { v4 as uuid } from 'uuid';

export const getDefaultproject = (isStaging: boolean, projects: Project[]): Project =>
  isStaging ? { id: 430, name: 'Sunteck: Staff Aug' } : projects[0];

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const getDefaultTeam = (
  isStaging: boolean,
  employees: Employee[],
  isDMorAdmin: boolean,
  defaultExperiences: Record<string, number>,
) =>
  isDMorAdmin && isStaging
    ? [
        {
          id: uuid(),
          role: 'Freelancer',
          control: 'rate',
          customRate: 0,
          hours: { billable: 0 },
        },
        {
          id: uuid(),
          role: 'Junior Developer',
          control: 'rate',
          customRate: +defaultExperiences['Junior Developer'].toFixed(4),
          hours: { billable: 0 },
        },
        {
          id: uuid(),
          role: 'Middle Developer',
          control: 'rate',
          customRate: +defaultExperiences['Middle Developer'].toFixed(4),
          hours: { billable: 0 },
        },
        {
          id: uuid(),
          role: 'Senior Developer',
          control: 'rate',
          customRate: +defaultExperiences['Senior Developer'].toFixed(4),
          hours: { billable: 0 },
        },
        {
          id: uuid(),
          role: 'Senior Developer',
          control: 'rate',
          customRate: +defaultExperiences['Senior Developer'].toFixed(4),
          hours: { billable: 0 },
        },
        {
          id: uuid(),
          role: 'Product manager',
          control: 'employeeSelect',
          employee: employees[0],
          hours: { billable: 0 },
        },
        {
          id: uuid(),
          role: 'Project Lead',
          control: 'employeeSelect',
          employee: employees[0],
          hours: { billable: 0 },
        },
        {
          id: uuid(),
          role: 'Coordinator',
          control: 'employeeSelect',
          employee: employees[0],
          hours: { billable: 0 },
        },
        {
          id: uuid(),
          role: 'DevOps',
          control: 'employeeSelect',
          employee: employees[0],
          hours: { billable: 0 },
        },
        {
          id: uuid(),
          role: 'QA',
          control: 'employeeSelect',
          employee: employees[0],
          hours: { billable: 0 },
        },
        {
          id: uuid(),
          role: 'Developer',
          control: 'employeeSelect',
          employee: employees[0],
          hours: { billable: 0 },
        },
      ]
    : [];
